import React, { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import intl from "react-intl-universal";
import { getConfig } from "@zilker/store-components";

import "./RegistrationSuccessfulPage.less";

const RegistrationSuccessfulPage: FC<RouteComponentProps> = () => {
  const { config } = getConfig();
  const { daikinLogoSrc, abcoLogoScr, stevensLogoSrc, usgLogoSrc } = config;
  const daikinUrl = `https://${intl.get("daikinComfortUrl")}`;
  const abcoUrl = `https://${intl.get("abcoUrl")}`;
  const stevensUrl = `https://${intl.get("stevensUrl")}`;
  const usgUrl = `https://${intl.get("usgUrl")}`;

  const siteLogos = [
    {
      url: daikinUrl,
      imgSrc: daikinLogoSrc
    },
    {
      url: abcoUrl,
      imgSrc: abcoLogoScr
    },
    {
      url: stevensUrl,
      imgSrc: stevensLogoSrc
    },
    { url: usgUrl, imgSrc: usgLogoSrc }
  ];

  return (
    <div className="registration-successful-page container">
      <div className="registration-successful-message-container">
        <h1>
          <span>{intl.get("welcome")}</span>
        </h1>
        <p>{intl.get("registration-successful-information")}</p>
        <div className="logo-wrapper">
          {siteLogos.map((logo, index) => {
            return (
              <a
                className="dast-link"
                href={logo.url}
                target="_blank"
                rel="noopener noreferrer"
                key={logo.url}
              >
                <div className="logo-item">
                  <img src={logo.imgSrc} alt={`logo ${index + 1}`} />
                  <p>{logo.url}</p>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withRouter(RegistrationSuccessfulPage);
