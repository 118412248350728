/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { useState, useEffect, useContext } from "react";
import intl from "react-intl-universal";
import { Link } from "react-router-dom";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import { Document } from "@bloomreach/spa-sdk";
import { Messagecontainer } from "@zilker/store-components";
import RichText from "../Bloomreach/components/RichText/rich-text";
import { isSpecialAirPurifier } from "../../../app/src/utils/helpers";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";

import "./paymentmethod.container.less";

interface PaymentMethodContainerProps {
  changePayment: (...args: any[]) => any;
  submitCreditCardPayment?: (...args: any[]) => any;
  isDelivery: boolean;
  blockKinpay: boolean;
  missingFields: string;
  resetMissingFields: () => void;
  cashUserOnlyError: string;
  openChat: (...args: any[]) => any;
  paymentMethods: any[];
}

export enum PaymentMethods {
  creditLine = "CREDIT_ACCOUNT",
  cash = "CASH",
  kinpay = "KINPAY"
}

const PaymentMethodContainer: React.FC<PaymentMethodContainerProps> = (
  props: PaymentMethodContainerProps
) => {
  const {
    changePayment,
    submitCreditCardPayment,
    isDelivery,
    blockKinpay,
    missingFields,
    resetMissingFields,
    cashUserOnlyError,
    openChat,
    paymentMethods
  } = props;

  const page = useContext(BrPageContext);
  const component = useContext(BrComponentContext) as any;
  const { document: documentRef } = component.getModels();
  const disclaimerDoc = documentRef && page.getContent<Document>(documentRef);

  const { content: disclaimerContent } = disclaimerDoc.getData();

  const [accepted, setAccepted] = useState<boolean>(false);
  const [kinpayDisabled, setKinpayDisabled] = useState<boolean>(false);
  const context = useContext<{
    user: any;
    cart: any;
    branches: any;
    account: any;
  }>(MainContext);
  const {
    user: {
      userProfile: { isTSMDealer }
    },
    account: {
      accountDetails: { creditCardAllowed, cashAllowed, creditLineAllowed }
    },
    cart: {
      selectedPayment,
      setPaymentUri,
      selectedPaymentMethodError,
      cartDetails: {
        defaultCart: { items }
      }
    }
  } = context;
  const shouldRenderPayment = method => {
    return (
      method === PaymentMethods.creditLine ||
      method === PaymentMethods.cash ||
      method === PaymentMethods.kinpay
    );
  };

  const paymentMap = new Map(
    paymentMethods.map(method => [
      method.name,
      shouldRenderPayment(method.name)
    ])
  );

  const { config }: { config: IEpConfig } = getConfig();

  function handleOptionChange(e) {
    // eslint-disable-next-line no-nested-ternary
    const payment = e.target.value
      ? e.target.value
      : cashAllowed === "true"
      ? PaymentMethods.cash
      : PaymentMethods.kinpay;

    const selectedPaymentMethodValue = paymentMethods.find(
      method => method.name === payment
    );
    changePayment(payment);
    if (selectedPaymentMethodValue) {
      setPaymentUri(selectedPaymentMethodValue.uri);
    }
    if (selectedPayment === PaymentMethods.kinpay) {
      setAccepted(true);
    }
  }

  function handleCheckboxChange(e) {
    setAccepted(!accepted);
  }

  const shouldDisableKinpay = (deliveryItems, pickupItems, branchesNums) => {
    const branchNumbers = branchesNums.filter(
      (value, index, array) => array.indexOf(value) === index
    );
    const {
      account: {
        accountDetails: { homeBranch }
      },
      branches
    } = context;
    const { airPurifierBranch } = branches;
    const { branchNumber: airPurifierBranchNumber } = airPurifierBranch;

    if (
      pickupItems.length &&
      (deliveryItems.length || branchNumbers.length > 1)
    ) {
      return true;
    }
    if (deliveryItems.length) {
      const hasAirPurifier = deliveryItems.find(item =>
        isSpecialAirPurifier(item._item[0]._code[0].code)
      );
      const hasOtherItems = deliveryItems.find(
        item => !isSpecialAirPurifier(item._item[0]._code[0].code)
      );
      if (
        deliveryItems.length > 1 &&
        homeBranch !== airPurifierBranchNumber &&
        hasAirPurifier &&
        hasOtherItems
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const pickupItemsArr = items.filter(
      item => item["shipping-method"] === "pickup"
    );

    const branchNumbers = pickupItemsArr.map(item => item["branch-number"]);

    const deliveryItemsArr = items.filter(
      item => item["shipping-method"] === "delivery"
    );
    const shouldKinpayBeDisabled = shouldDisableKinpay(
      deliveryItemsArr,
      pickupItemsArr,
      branchNumbers
    );
    const shouldDisableCreditCard = creditCardAllowed === "false";
    setKinpayDisabled(shouldKinpayBeDisabled || shouldDisableCreditCard);
  }, [items]);

  return (
    <div className="payment-method-container">
      <h3
        className={`section-subtitle ${
          selectedPaymentMethodError || cashUserOnlyError
            ? "validation-error-border"
            : ""
        }`}
      >
        <span className="star">*</span>
        {intl.get("payment-method")}
      </h3>
      <p className="section-subtitle-error">{selectedPaymentMethodError}</p>
      {cashUserOnlyError && (
        <div className="section-subtitle-error">
          <p className="subtitle-error-paragraph">
            {intl.get("credit-line-unavailable-pt1")}
            <span>{intl.get("credit-line-unavailable-pt2")}</span>
            {intl.get("credit-line-unavailable-pt3")}
            <button type="button" className="support-btn" onClick={openChat}>
              {intl.get("support-button")}
            </button>
          </p>
          <p className="subtitle-error-paragraph">
            {intl.get("credit-line-unavailable-pt4")}
          </p>
        </div>
      )}
      <div className="row">
        <div className="col-12 col-md-6">
          {paymentMap.get(PaymentMethods.creditLine) ? (
            <div className="form-group">
              <div className="form-check">
                <input
                  type="radio"
                  id="credit-line"
                  value={PaymentMethods.creditLine}
                  className="form-check-input"
                  onChange={handleOptionChange}
                  checked={selectedPayment === PaymentMethods.creditLine}
                  disabled={creditLineAllowed === "false"}
                />
                <label className="form-check-label" htmlFor="credit-line">
                  {intl.get(PaymentMethods.creditLine)}
                </label>
              </div>
            </div>
          ) : null}

          {paymentMap.get(PaymentMethods.cash) ||
          paymentMap.get(PaymentMethods.kinpay) ? (
            <div className="form-group">
              <div className="form-check">
                <input
                  type="radio"
                  id="cash"
                  value=""
                  className="form-check-input"
                  onChange={handleOptionChange}
                  disabled={cashAllowed === "false" && kinpayDisabled}
                  checked={
                    selectedPayment === PaymentMethods.cash ||
                    selectedPayment === PaymentMethods.kinpay
                  }
                />
                <label className="form-check-label" htmlFor="cash">
                  {intl.get("cash-payment")}
                  {!config.hideCreditCardPayment && intl.get("credit-payment")}
                </label>
              </div>
            </div>
          ) : null}

          <div
            className={`cash-options ${
              selectedPayment === PaymentMethods.cash ||
              selectedPayment === PaymentMethods.kinpay
                ? "selected-cash"
                : ""
            }`}
          >
            {paymentMap.get(PaymentMethods.cash) ? (
              <div className="form-group">
                <div className="form-check">
                  <input
                    type="radio"
                    id="cash-payment"
                    value={PaymentMethods.cash}
                    className="form-check-input"
                    onChange={handleOptionChange}
                    checked={selectedPayment === PaymentMethods.cash}
                    disabled={cashAllowed === "false"}
                  />
                  <label className="form-check-label" htmlFor="cash-payment">
                    {intl.get(PaymentMethods.cash)}
                  </label>
                </div>
              </div>
            ) : null}
            {!isTSMDealer &&
            !config.hideCreditCardPayment &&
            paymentMap.get(PaymentMethods.kinpay) ? (
              <div className="form-group">
                <div className="form-check">
                  <input
                    type="radio"
                    id="credit-card"
                    value={PaymentMethods.kinpay}
                    className="form-check-input"
                    onChange={handleOptionChange}
                    checked={selectedPayment === PaymentMethods.kinpay}
                    disabled={kinpayDisabled}
                  />
                  <label className="form-check-label" htmlFor="credit-card">
                    {intl.get(PaymentMethods.kinpay)}
                  </label>
                </div>
                {kinpayDisabled && (
                  <p className="form-unavailability-message">
                    <sup>*</sup>
                    {intl.get("unable-to-offer-credit-card-payment")}
                  </p>
                )}
              </div>
            ) : null}
          </div>
        </div>

        <div className="col-12 col-md-6">
          {selectedPayment === PaymentMethods.kinpay && !blockKinpay ? (
            <div className="content-box">
              {isDelivery ? (
                <div className="disclaimer">
                  <RichText content={disclaimerContent.value} />
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="accept"
                        value="accept"
                        className="form-check-input"
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="accept">
                        {intl.get("Accept")}
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="pay-button">
                <button
                  className="dast-btn dast-btn-primary"
                  aria-label={intl.get("pay-with-credit-card")}
                  type="submit"
                  disabled={isDelivery && !accepted}
                  onClick={submitCreditCardPayment}
                >
                  {intl.get("pay-with-credit-card")}
                </button>
              </div>
              {missingFields && (
                <Messagecontainer
                  message={{
                    type: "",
                    debugMessages: missingFields
                  }}
                  closeContainerHandler={resetMissingFields}
                />
              )}
            </div>
          ) : null}
          {blockKinpay && (
            <p className="kinpay-block-error-message">
              {intl.get("locked-contract-error-1")}{" "}
              <Link to="contactus">{intl.get("contact-us").toLowerCase()}</Link>
              {intl.get("locked-contract-error-2")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodContainer;
