/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { useState, useEffect, useContext, FC } from "react";
import intl from "react-intl-universal";
import { Input, getConfig } from "@zilker/store-components";
import MessageContainer from "@zilker/store-components/src/MessageContainer/messagecontainer";
import { withRouter, RouteComponentProps } from "react-router-dom";
import contactUsFormValidor from "../validation/contactUsFormValidation";
import { sendContactUsForm } from "../services/connectServices";
import { MainContext } from "../contexts/MainContext";
import { checkTokensExpired, pushToMaintenace } from "../utils/helpers";

import "../theme/sharedClasses.less";
import "./ContactUsPage.less";

interface inputForm {
  email: string;
  name: string;
  phone: string;
  company: string;
  subject: string;
  message: string;
}

interface formErrors {
  email: string;
  name: string;
  subject: string;
  message: string;
}

interface infoMessage {
  debugMessages: string;
  id: string;
  type: string;
}

const ContactUsPage: FC<RouteComponentProps> = ({ history }) => {
  const context = useContext<{ auth: any; user: any; account: any }>(
    MainContext
  );
  const {
    user,
    auth: { logout },
    account
  } = context;
  const {
    userProfile: { givenName, familyName, phone, email }
  } = user;
  const {
    accountDetails: { customerNumber, company }
  } = account;

  const subjectOptions = [
    { key: "", value: intl.get("select-subject") },
    { key: "account_registration", value: intl.get("account-registration") },
    {
      key: "credentials_inquiry_support",
      value: intl.get("credentials-inquiry-support")
    },
    {
      key: "can_not_find_products",
      value: intl.get("can-not-find-products")
    },
    {
      key: "technical_website_issues",
      value: intl.get("technical-website-issues")
    },
    {
      key: "technical_product_help",
      value: intl.get("technical-product-help")
    },
    {
      key: "commercial_equipment_support",
      value: intl.get("commercial-equipment-support")
    },
    {
      key: "parts_and_supplies_support",
      value: intl.get("parts-and-supplies-support")
    },
    {
      key: "suggestions_site_feedback",
      value: intl.get("suggestions-site-feedback")
    },
    { key: "general_inquiry", value: intl.get("general-inquiry") }
  ];

  const inputForm: inputForm = {
    email: user.userProfile.email,
    name: `${user.userProfile.givenName} ${user.userProfile.familyName}`,
    phone: `${user.userProfile.phone}`,
    company: account.accountDetails.company
      ? `${account.accountDetails.company} - ${account.accountDetails.customerNumber}`
      : "",
    subject: "",
    message: ""
  };

  const errorsInForm: formErrors = {
    email: "",
    name: "",
    subject: "",
    message: ""
  };

  const infoMessage: infoMessage = {
    debugMessages: "",
    id: "",
    type: ""
  };

  const [form, setForm] = useState(inputForm);
  const [formErrors, setErrors] = useState(errorsInForm);
  const [infoMsg, setInfoMsg] = useState(infoMessage);

  const { config } = getConfig();
  const { defaultChannel } = config.brXM;
  const wideButton = defaultChannel === "motili";

  useEffect(() => {
    setForm(prevState => {
      const updatedState = { ...form };
      updatedState.name = `${givenName} ${familyName}`;
      updatedState.email = email;
      updatedState.phone = phone;
      updatedState.company = company ? `${company} - ${customerNumber}` : "";
      return { ...prevState, ...updatedState };
    });
  }, [givenName, familyName, email, company, customerNumber]);

  function inputHandler(e) {
    e.persist();
    setForm(prevState => {
      const updatedState = { ...form };
      updatedState[e.target.name] = e.target.value;
      return { ...prevState, ...updatedState };
    });
    setErrors(errorsInForm);
  }

  function submitForm() {
    const { errors, isValid } = contactUsFormValidor(form);
    let msg: infoMessage;
    if (!isValid) {
      setErrors(errors);
      return;
    }

    const message = JSON.stringify(form);

    sendContactUsForm(message)
      .then(res => {
        msg = {
          debugMessages: "Message sent",
          id: "",
          type: "needinfo"
        };
        setInfoMsg(msg);
        setForm(inputForm);
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => sendContactUsForm => ContactUsPage.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "sendContactUsForm => ContactUsPage.tsx"
          });
        }
      });
  }

  function closeMessageContainer() {
    setInfoMsg(infoMessage);
  }

  function isGuestUser() {
    return email === "";
  }
  return (
    <div
      className="viewport ui-container static-ui-container container-fluid"
      data-region="viewPortRegion"
      style={{ display: "block" }}
    >
      <div>
        <div data-region="appMain" style={{ display: "block" }}>
          <MessageContainer
            message={infoMsg}
            closeContainerHandler={closeMessageContainer}
          />
          <div className="contact-us">
            <div className="static-container container">
              <div className="static-container-inner content-box">
                <h4>{intl.get("contact-us")}</h4>
                <div
                  className="static-main-container"
                  style={{ display: "block" }}
                />
                <Input
                  label={intl.get("email")}
                  type="email"
                  inputName="email"
                  ariaLabel={intl.get("email")}
                  inputHandler={inputHandler}
                  value={form.email}
                  errors={formErrors}
                  required
                />
                <Input
                  label={intl.get("name")}
                  type="text"
                  inputName="name"
                  ariaLabel={intl.get("name")}
                  inputHandler={inputHandler}
                  value={form.name}
                  errors={formErrors}
                  required
                />
                <Input
                  label={intl.get("phone")}
                  type="telephone"
                  inputName="phone"
                  ariaLabel={intl.get("phone")}
                  inputHandler={inputHandler}
                  value={form.phone}
                  errors={formErrors}
                  required={false}
                />
                <Input
                  label={intl.get("company")}
                  type="text"
                  inputName="company"
                  ariaLabel={intl.get("company")}
                  inputHandler={inputHandler}
                  value={form.company}
                  errors={formErrors}
                  required={false}
                  disabled={!isGuestUser()}
                />
                <div className="input-component">
                  <p className="label">
                    <span>*</span>
                    {intl.get("subject")}
                  </p>
                  <select
                    name="subject"
                    className={
                      formErrors.subject ? "validation-error-border" : null
                    }
                    onChange={inputHandler}
                    value={form.subject ? form.subject : ""}
                  >
                    {subjectOptions.map(option => (
                      <option key={option.key} value={option.key}>
                        {option.value}
                      </option>
                    ))}
                  </select>
                </div>
                <p className="validation-error">
                  {formErrors.subject && formErrors.subject}
                </p>
                <div className="input-component">
                  <p className="label">
                    <span>*</span>
                    {intl.get("message")}
                  </p>
                  <textarea
                    name="message"
                    onChange={inputHandler}
                    value={form.message}
                    className={
                      formErrors.message ? "validation-error-border" : null
                    }
                  />
                </div>
                <p className="validation-error">
                  {formErrors.message && formErrors.message}
                </p>
                <button
                  className={`dast-btn dast-btn-primary ${
                    wideButton ? "wide" : ""
                  }`}
                  type="button"
                  onClick={submitForm}
                  aria-label={
                    wideButton ? intl.get("search") : intl.get("send")
                  }
                >
                  {wideButton ? intl.get("search") : intl.get("send")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ContactUsPage);
