import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import intl from "react-intl-universal";
import Modal from "react-responsive-modal";
import {
  AddressFormMain,
  PaymentMethodContainer,
  getConfig,
  cortexFetch,
  zoom,
  Messagecontainer,
  PaymentMethods
} from "@zilker/store-components";
import { NotificationsStatus } from "@zilker/store-components/src/Notifications/Notifications";
import { MainContext } from "../../../app/src/contexts/MainContext";
import {
  checkResponse,
  checkTokensExpired,
  pushToMaintenace,
  formatCartOrderDetails
} from "../../../app/src/utils/helpers";

interface PaymentSectionProps {
  orderData: any;
  openAddressModal: boolean;
  addressUrl: any;
  disableCompleteOrder: boolean;
  missingFields: string;
  handleCloseAddressModal: (...args: any[]) => any;
  fetchOrderData: (...args: any[]) => any;
  setNewAddressUri: (addressUri: string) => void;
  openChat: (...args: any[]) => any;
  validateCheckoutForm: (...args: any[]) => any;
  editOrderDetails: (...args: any[]) => any;
  disableCompletingOrder: (...args: any[]) => any;
  closeTostMessage: (...args: any[]) => any;
  resetMissingFields: (...args: any[]) => any;
  disabledNotifications: NotificationsStatus;
}

const PaymentSection: React.FC<PaymentSectionProps> = props => {
  const {
    orderData,
    openAddressModal,
    addressUrl,
    disableCompleteOrder,
    missingFields,
    handleCloseAddressModal,
    fetchOrderData,
    setNewAddressUri,
    openChat,
    validateCheckoutForm,
    editOrderDetails,
    disableCompletingOrder,
    closeTostMessage,
    resetMissingFields,
    disabledNotifications
  } = props;

  const history = useHistory();

  const { config } = getConfig();

  const context = useContext<{
    auth: any;
    order: any;
    cart: any;
    user: any;
    contract: any;
    branches: any;
    job: any;
    account: any;
  }>(MainContext);

  const {
    cart: { cashUserOnlyError, selectedShippingOption }
  } = context;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openKinpayModal, setOpenKinpayModal] = useState<boolean>(false);
  const [kinpayApproved, setKinpayApproved] = useState<boolean>(false);
  const [kinpayUrl, setKinpayUrl] = useState<string>("");
  const [kinpayPrid, setKinpayPrid] = useState<string>("");
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
  const [blockKinpay, setBlockKinpay] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState([]);

  const renderNewAddressModal = () => {
    const { auth } = context;
    const newOrEdit =
      addressUrl && addressUrl.address ? intl.get("edit") : intl.get("new");

    return (
      <Modal open={openAddressModal} onClose={handleCloseAddressModal}>
        <div className="modal-lg new-address-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                {newOrEdit} {intl.get("address")}
              </h2>
            </div>
            <div className="modal-body">
              <AddressFormMain
                onCloseModal={handleCloseAddressModal}
                fetchData={fetchOrderData}
                addressData={addressUrl}
                history={history}
                auth={auth}
                onNewAddressWarning={setNewAddressUri}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const payWithCreditCard = async () => {
    // Kinpay returns data to the window, this block handles the callback data

    if (!validateCheckoutForm()) {
      return;
    }

    try {
      setIsLoading(true);

      const { _order } = orderData;

      const {
        auth: { logout }
      } = context;

      const {
        cortexApi: { scope }
      } = config;

      const orderUri = _order[0].self.uri;
      const orderId = orderUri.slice(
        orderUri.lastIndexOf(scope) + scope.length
      );

      const fetchLink = `/orderdetails/${scope}${orderId}/kinpayrequesturl/form?followlocation&format=standardlinks,zoom.nodatalinks&`;

      const kinpayLinkResponse = await editOrderDetails()
        .then(() => {
          return cortexFetch(fetchLink, {
            method: "post",
            body: JSON.stringify({})
          });
        })
        .then(res => checkResponse(res))
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => payWithCreditCard => PaymentSection.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "payWithCreditCard => PaymentSection.tsx"
            });
          }
        });

      const {
        "kinpay-url": kinpayUrlConst,
        "kinpay-prid": kinpayPridConst
      } = kinpayLinkResponse;

      if (kinpayUrlConst && kinpayPridConst) {
        sessionStorage.setItem("kinpayPrid", kinpayPridConst);
        setKinpayUrl(`${kinpayUrlConst}?option=postMessage`);
        setKinpayPrid(kinpayPridConst);
        setOpenKinpayModal(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      pushToMaintenace(history, {
        e: { message: intl.get("unable-to-pay-with-credit-card") },
        errIn: "payWithCreditCard => PaymentSection.tsx"
      });
    }
  };

  const closeKinpayModal = () => {
    setOpenKinpayModal(false);
  };

  const updateBranchAndJob = () => {
    const {
      order,
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { findBranch },
      job
    } = context;

    const currentJobNumber = defaultCart ? defaultCart.jobNumber : null;
    const currentJobName = defaultCart ? defaultCart.jobName : null;

    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    const selectedBranchDetails =
      selectedBranch && findBranch(selectedBranch.code);

    order.setBranch(selectedBranchDetails);

    if (currentJobNumber && currentJobName) {
      order.setJob({ currentJobNumber, currentJobName });

      /**
       * After the order is completed, user's session is still active
       * and we can assume the user wants to keep using the same job.
       * That's why we persist the job name and number.
       */

      job.setPersistedJobNumber(currentJobNumber);
      job.setPersistedJobName(currentJobName);
    }
  };

  const completeKinpayOrder = async () => {
    const {
      auth: { isLoggedIn, logout }
    } = context;

    const { zoomOrderReview, zoomPurchase } = zoom;

    try {
      if (isLoggedIn) {
        let res = await cortexFetch(`/?zoom=${zoomOrderReview.sort().join()}`);

        res = await checkResponse(res);

        const orderData1 = res._defaultcart[0];

        const purchaseform = orderData1._order[0]._purchaseform[0].links.find(
          link => link.rel === "submitorderaction"
        ).uri;

        const { order } = context;

        let purchaseData = await cortexFetch(
          `${purchaseform}?followlocation=true&zoom=${zoomPurchase
            .sort()
            .join()}`,
          {
            method: "post"
          }
        );

        purchaseData = await checkResponse(purchaseData);

        order.setOrderData(orderData1);
        order.setPurchaseData(purchaseData);
        updateBranchAndJob();

        history.push("/purchaseReceipt");
      }
    } catch (e) {
      if (checkTokensExpired(e)) {
        logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "Logout => completeKinpayOrder => PaymentSection.tsx"
          })
        );
      } else {
        pushToMaintenace(history, {
          e,
          errIn: "completeKinpayOrder => PaymentSection.tsx"
        });
      }
    }
  };

  const showRejectedTestMessage = () => {
    return disableCompleteOrder ? (
      <div className="kinpay-fail">
        <Messagecontainer
          message={{
            type: "needinfo",
            debugMessages: intl.get("unable-to-pay-with-credit-card")
          }}
          closeContainerHandler={closeTostMessage}
        />
      </div>
    ) : null;
  };

  // KinPay returns data to the window, this function handles the callback data
  const handleKinpayCallback = async event => {
    const messageFromSender = event.data;
    /**
     * @todo handle each message error type individually rather than catch all
     */
    if (messageFromSender.message === "Approved" && !kinpayApproved) {
      setKinpayApproved(true);
      completeKinpayOrder();
    } else if (
      typeof messageFromSender === "object" &&
      "message" in messageFromSender
    ) {
      setTimeout(() => {
        disableCompletingOrder();
        closeKinpayModal();
        showRejectedTestMessage();
      }, 3000);
    }
  };

  const renderKinpayModal = () => {
    const styles = {
      modal: {
        maxWidth: "1280px",
        width: "100%"
      }
    };

    return kinpayUrl && kinpayPrid ? (
      <Modal
        open={openKinpayModal}
        onClose={closeKinpayModal}
        styles={{ modal: styles.modal }}
      >
        <iframe
          className="kinpay-modal"
          src={kinpayUrl}
          frameBorder="0"
          title="kinpay window"
        />
      </Modal>
    ) : null;
  };

  const fetchCartOrderDetailsForm = async () => {
    const {
      user: {
        userProfile: { email: defaultEmail }
      },
      auth: { logout },
      cart: {
        setOrderDetails,
        setOrderInformationData,
        setClient,
        setNotificationStatus,
        setNotificationData
      },
      account: {
        accountDetails: { showPriceOnPickedTicket }
      }
    } = context;

    cortexFetch(`/?zoom=${zoom.zoomFetchCartOrderDetailsForm}`)
      .then(res => checkResponse(res))
      .then(res => {
        const { _cartorderdetails } = res._defaultcart[0]._order[0];
        const cartOrderDetail = formatCartOrderDetails(
          _cartorderdetails[0],
          defaultEmail,
          showPriceOnPickedTicket,
          disabledNotifications
        );

        let populatedClient;
        if (
          cartOrderDetail.clientInformation["client-name"] &&
          cartOrderDetail.clientInformation["client-id"]
        ) {
          populatedClient = {
            name: cartOrderDetail.clientInformation["client-name"],
            id: cartOrderDetail.clientInformation["client-id"]
          };
        }
        setOrderDetails(_cartorderdetails[0]);
        setOrderInformationData(cartOrderDetail.ordersInformation);
        setNotificationStatus(
          config.showUserNotifications
            ? cartOrderDetail.notificationStatus
            : disabledNotifications
        );
        setNotificationData(cartOrderDetail.notificationData);
        setClient(prevClient => populatedClient || prevClient);
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => fetchCartOrderDetailsForm => CheckoutPageFunctional.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "fetchCartOrderDetailsForm => CheckoutPageFunctional.tsx"
          });
        }
      });
  };

  const populateSelectedPaymentOption = async () => {
    const {
      cart: { setPayment, setPaymentUri },
      account: {
        accountDetails: { creditCardAllowed, cashAllowed, creditLineAllowed }
      }
    } = context;
    const {
      cortexApi: { scope }
    } = config;
    let selectedPaymentOption = "";

    if (creditLineAllowed === "true") {
      selectedPaymentOption = PaymentMethods.creditLine;
    } else if (creditLineAllowed === "false" && cashAllowed === "true") {
      selectedPaymentOption = PaymentMethods.cash;
    } else if (
      creditLineAllowed === "false" &&
      cashAllowed === "false" &&
      creditCardAllowed === "true"
    ) {
      selectedPaymentOption = PaymentMethods.kinpay;
    }

    if (selectedPaymentOption) {
      const selectedPaymentUri = availablePaymentMethods.find(
        method => method.name === selectedPaymentOption
      );
      setPayment(selectedPaymentOption);
      if (selectedPaymentUri) {
        setPaymentUri(selectedPaymentUri.uri);
      }
      await fetchCartOrderDetailsForm();
      // await fetchOrderData();
      await handlePaymentChange(selectedPaymentOption);
    }
  };

  const checkContract = async () => {
    const {
      cart: {
        cartDetails: {
          defaultCart: { cartOrderDetailsForm }
        }
      },
      account: {
        accountDetails: { customerNumber }
      },
      contract: { fetchContract }
    } = context;
    if (cartOrderDetailsForm["contract-number"]) {
      const contract = await fetchContract(
        customerNumber,
        cartOrderDetailsForm["contract-number"]
      );
      const { status } = contract;
      if (status === "L") {
        setBlockKinpay(true);
      }
    }
  };

  const handlePaymentChange = async paymentOption => {
    const {
      auth: { logout },
      user: {
        userProfile: { isCanadianUser }
      },
      cart: {
        setPayment,
        setPaymentUri,
        setPaymentMethodError,
        setCashUserError
      }
    } = context;

    if (orderData) {
      if (paymentOption === PaymentMethods.kinpay) {
        await checkContract();
      } else {
        setBlockKinpay(false);
      }

      try {
        const selectedPaymentUri = availablePaymentMethods.find(
          method => method.name === paymentOption
        );
        setPayment(paymentOption);
        if (selectedPaymentUri) {
          setPaymentUri(selectedPaymentUri.uri);
        }
        setPaymentMethodError("");
        setCashUserError("");

        updateBranchAndJob();
        if (isCanadianUser) {
          await fetchOrderData();
        }
      } catch (e) {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => handlePaymentChange => CheckoutPageFunctional.tsx"
            })
          );
        } else {
          setIsLoading(false);

          pushToMaintenace(history, {
            e,
            errIn: "handlePaymentChange => CheckoutPageFunctional.tsx"
          });
        }
      }
    }
  };

  const fetchAvailablePaymentMethods = () => {
    const { _order } = orderData;

    const {
      cortexApi: { scope }
    } = config;
    const {
      auth: { logout }
    } = context;

    const orderUri = _order[0].self.uri;
    const orderId = orderUri.slice(orderUri.lastIndexOf(scope) + scope.length);
    setPaymentLoading(true);
    cortexFetch(
      `/paymentmethods/orders/${scope}${orderId}?zoom=element&format=standardlinks`
    )
      .then(res => checkResponse(res))
      .then(res => {
        setPaymentLoading(false);
        if (res._element) {
          const paymentMethods = res._element.map(method => ({
            name: method.name,
            uri: method.self.uri
          }));
          setAvailablePaymentMethods(paymentMethods);
        }
      })
      .catch(e => {
        setPaymentLoading(false);
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => fetchAvailablePaymentMethods => paymentsection.main.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "fetchAvailablePaymentMethods => paymentsection.main.tsx"
          });
        }
      });
  };

  useEffect(() => {
    window.addEventListener("message", handleKinpayCallback);
    fetchAvailablePaymentMethods();
    return () => {
      sessionStorage.removeItem("kinpayPrid");
      window.removeEventListener("message", handleKinpayCallback);
    };
  }, []);

  useEffect(() => {
    const {
      cart: { selectedPayment },
      account: {
        accountDetails: { creditCardAllowed, cashAllowed, creditLineAllowed }
      }
    } = context;

    populateSelectedPaymentOption();
    setPaymentMethod(selectedPayment);
  }, [availablePaymentMethods]);

  useEffect(() => {
    const {
      cart: { selectedPayment },
      account: {
        accountDetails: { creditCardAllowed, cashAllowed, creditLineAllowed }
      }
    } = context;

    if (
      creditLineAllowed &&
      cashAllowed &&
      creditCardAllowed &&
      !selectedPayment &&
      orderData
    ) {
      populateSelectedPaymentOption();
    }
  }, [paymentMethod, orderData]);

  return (
    <>
      <h4 className="section-title">{intl.get("billing")}</h4>
      {renderKinpayModal()}
      {renderNewAddressModal()}
      <div className="row">
        <div className="col-12">
          {paymentLoading || isLoading ? (
            <div className="loader" />
          ) : (
            <PaymentMethodContainer
              changePayment={handlePaymentChange}
              submitCreditCardPayment={payWithCreditCard}
              isDelivery={
                !!(
                  selectedShippingOption &&
                  selectedShippingOption.name.includes("Branch_Truck")
                )
              }
              blockKinpay={blockKinpay}
              missingFields={missingFields}
              resetMissingFields={resetMissingFields}
              cashUserOnlyError={cashUserOnlyError}
              openChat={openChat}
              paymentMethods={availablePaymentMethods}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentSection;
