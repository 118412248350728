import React from "react";
import DOMPurify from "dompurify";
import "./SiteLogos.less";

const SiteLogos = props => {
  const { logos, content } = props;

  const singleLogoSite = logos && logos.length === 1;

  function createMarkup() {
    const sanitizedContent = DOMPurify.sanitize(content);
    const modifiedContent = sanitizedContent.replace(
      /<img((?![\w\s]*alt=)[\w\s\S]*?)>/g,
      '<img$1 alt="Supporting image for Footer" >'
    );
    return { __html: modifiedContent };
  }
  return (
    <div
      className={`footer-logo dast-paragraph ${
        singleLogoSite ? "single-footer-logo" : ""
      }`}
    >
      <div
        className={`logos-container ${
          singleLogoSite ? "single-logo-container" : ""
        }`}
      >
        {logos.map(
          (logo: any, index: number) =>
            logo && (
              <a href="/" key={logo}>
                <img
                  src={logo}
                  alt={`logo ${index + 1}`}
                  className={`footer-logo-image ${
                    singleLogoSite && index === 0 ? "single-logo" : ""
                  }`}
                />
              </a>
            )
        )}
      </div>

      <div
        className="content-container"
        dangerouslySetInnerHTML={createMarkup()}
      />
    </div>
  );
};

export default SiteLogos;
