import React, { useState, useEffect, useContext } from "react";
import { useRouteMatch, Redirect } from "react-router-dom";
import intl from "react-intl-universal";
import {
  BrProps,
  BrComponent,
  BrPageContext,
  BrComponentContext
} from "@bloomreach/react-sdk";
import { Component, ContainerItem } from "@bloomreach/spa-sdk";
import Modal from "react-responsive-modal";
import {
  ProfileInfoMain,
  ProfileAddressesMain,
  AddressFormMain,
  NotificationsContainer,
  DefaultBranchInfoCard,
  RichText,
  Messagecontainer,
  page as StorePage,
  PreferencesContainer,
  getConfig
} from "@zilker/store-components";
import {
  NotificationsStatus,
  NotificationData
} from "@zilker/store-components/src/Notifications/Notifications";
import MessageContainer from "@zilker/store-components/src/MessageContainer/messagecontainer";
import { DebugMessage } from "../../../components/src/MessageContainer/messagecontainer";
import { checkTokensExpired, pushToMaintenace } from "../utils/helpers";
import { updateDefaultProfile } from "../services/EpServices";

import "../theme/sharedClasses.less";
import "./ProfilePage.less";

interface UserAddresses {
  self: any;
  messages: Array<any>;
  links: Array<any>;
  _addressform: Array<any>;
  _billingaddresses: Array<any>;
  _element: Array<any>;
}
interface UserProfile {
  familyName: string;
  givenName: string;
  phone: string;
  fax: string;
  preferedCurrency: string;
  preferredLocale: string;
  email: string;
  city: string;
  postalCode: string;
  region: string;
  street: string;
  country: string;
  optInSmsAlert: string;
  optInOrderStatusEmail: string;
  optInDeliveryStatusEmail: string;
  smsPhoneNumbers: any;
  orderStatusEmails: any;
  deliveryStatusEmails: any;
  businessNumber: string;
  htmlEmail: string;
  taxЕxemptionId: string;
  custAttribute1: string;
  custAttribute2: string;
  addresses: Array<UserAddresses>;
  profileUri: string;
  defaultShippingAddress: any;
  extendedBillingAddress: any;
  defaultShippingAddressString: string;
  isCanadianUser: boolean;
  clientList: any;
  onlineBillPayLink: string;
  companyName: string;
  optOutMarketing: string;
  accountUsers: any;
  roles: any;
  ecommerceUser: boolean;
}

interface ProfileInfoInterface {
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  companyName?: string;
}

const initialDebugMessage: DebugMessage = {
  type: "",
  debugMessages: ""
};

interface ResponseMessage {
  redirect: boolean;
  notifications?: DebugMessage;
  preferences?: DebugMessage;
}

interface NewAddress {
  openAddressModal: boolean;
  addressUrl: {
    [key: string]: string;
  };
}

interface ProfilePageProps extends BrProps {
  history: any;
  match: any;
  auth: any;
  user: any;
  account: any;
}

const ProfilePage: React.FC<ProfilePageProps> = ({
  history,
  match,
  auth,
  user,
  account
}) => {
  const page = useContext(BrPageContext);
  const component = useContext(BrComponentContext) as any;

  const childRef = component.model.children[0];
  const richTextComponent = page.getContent(childRef) as any;
  const rtDocument = page.getContent(richTextComponent.models.document);
  const { content: rtContent } = rtDocument.getData();

  const { userProfile }: { userProfile: UserProfile } = user;
  const { ecommerceUser } = userProfile;
  const {
    accountDetails: { membership, admins, isInitialData, homeBranch }
  } = account;
  const { config } = getConfig();
  const { supportEmail, chatEnabled } = config;
  const { defaultChannel }: { defaultChannel: string } = config.brXM;
  const showPageTitle: boolean = defaultChannel === "motili";

  const userInfo: ProfileInfoInterface = {
    email: userProfile.email,
    firstName: userProfile.givenName,
    lastName: userProfile.familyName,
    phone: userProfile.phone
  };
  // const [dataPolicyData, setDataPolicyData] = useState(null);
  // const [showResetPasswordButton, setShowResetPasswordButton] = useState(null);
  /** 
   * TODO: These are not present in the EP response default profile:
    res is response for user default profile:
    
    if (res && res._passwordresetform) {
    setShowResetPasswordButton({ showResetPasswordButton: true });
    }

    setDataPolicyData({dataPolicyData: res["_data-policies"]
      ? res["_data-policies"][0]
      : null})
  */
  const [newAddress, setNewAddress] = useState<NewAddress>({
    openAddressModal: false,
    addressUrl: { address: "" }
  });

  const path = useRouteMatch().url.includes("myAccount")
    ? "main/myAccount/profile"
    : "main/profile";

  const [responseMessage, setResponseMessage] = useState<ResponseMessage>({
    redirect: false,
    notifications: initialDebugMessage,
    preferences: initialDebugMessage
  });

  const [userMessage, setUserMessage] = useState<string>("");

  const [newAddressUri, setNewAddressUri] = useState<string>("");

  useEffect(() => {
    StorePage();
  }, []);

  const fetchProfileData = async (): Promise<any> => {
    await user.getUserProfile();
  };

  // const changePassword = () => {
  // const history = useHistory();
  //   history.push("/password_change", { returnPage: "/profile" });
  // };

  const handleNewAddress = (): void => {
    setNewAddress({
      openAddressModal: true,
      addressUrl: undefined
    });
  };

  const handleEditAddress = (addressLink: string): void => {
    setNewAddress({
      openAddressModal: true,
      addressUrl: { address: addressLink }
    });
  };

  const handleCloseAddressModal = (): void => {
    setNewAddress(prevState => ({ ...prevState, openAddressModal: false }));
  };

  const openChat = () => window.zE.activate();

  const closeMessageContainer = (sectionName: string): void => {
    setResponseMessage(prevState => ({
      ...prevState,
      [sectionName]: initialDebugMessage
    }));
  };

  const renderNewAddressModal = () => {
    const { openAddressModal, addressUrl } = newAddress;
    const newOrEdit: string =
      addressUrl && addressUrl.address ? intl.get("edit") : intl.get("new");
    return (
      <Modal open={openAddressModal} onClose={handleCloseAddressModal}>
        <div className="modal-lg new-address-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                {newOrEdit} {intl.get("address")}
              </h2>
            </div>
            <div className="modal-body">
              <AddressFormMain
                onCloseModal={handleCloseAddressModal}
                fetchData={fetchProfileData}
                addressData={addressUrl}
                history={history}
                auth={auth}
                onNewAddressWarning={setNewAddressUri}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const showMessage = (message: string): void => {
    setUserMessage(message);
  };

  const closeMessage = (): void => setUserMessage("");

  /**
   * ## updateProfileSection
   * @param updates any - updated profile fields
   * @param sectionName string - "preferences", "notifications"
   *
   * @description Function calls service for updating default profile,
   * and then refreshes the profile data.
   */
  const updateProfileSection = (updates: any, sectionName: string): void => {
    updateDefaultProfile(updates, userProfile)
      .then(response => {
        const { type } = response;
        const resMessage: DebugMessage = {
          type,
          debugMessages: ""
        };

        resMessage.debugMessages = intl.get(`${sectionName}-alerts-success`);

        setResponseMessage(prevState => ({
          ...prevState,
          [sectionName]: resMessage
        }));
        setTimeout(() => {
          setResponseMessage(prevState => ({
            ...prevState,
            [sectionName]: initialDebugMessage
          }));
        }, 3000);

        // Refresh profile data after the update.
        fetchProfileData();
      })
      .catch(error => {
        console.error(error.message);
        if (checkTokensExpired(error)) {
          auth.logout().catch(logoutErr =>
            pushToMaintenace(history, {
              e: logoutErr,
              errIn: "Logout => updateProfileSection => ProfilePage.tsx"
            })
          );
        } else {
          setResponseMessage(prevState => ({
            ...prevState,
            redirect: true,
            [sectionName]: {
              type: "error",
              debugMessages: intl.get(`${sectionName}-alerts-error`)
            }
          }));
        }
      });
  };

  function updateNotifications(
    notifications: NotificationsStatus,
    userData: NotificationData
  ): void {
    const {
      smsChecked,
      orderStatusChecked,
      deliveryStatusChecked
    } = notifications;

    const { phoneNumbers, orderStatusEmails, deliveryStatusEmail } = userData;

    const updates = {
      "opt-in-sms-alert": smsChecked,
      "opt-in-order-status-email": orderStatusChecked,
      "opt-in-delivery-status-email": deliveryStatusChecked,
      "sms-phone-numbers": smsChecked
        ? phoneNumbers.filter(phoneNumber => phoneNumber).join("|")
        : "",
      "order-status-emails": orderStatusChecked
        ? orderStatusEmails.filter(email => email).join("|")
        : "",
      "delivery-status-emails": deliveryStatusChecked ? deliveryStatusEmail : ""
    };

    updateProfileSection(updates, "notifications");
  }

  const mailToButton = () => {
    const emailAddress: string = config.accountVerificationEmail;

    return (
      <div className="verification-form-card">
        <a
          href={`mailto:${emailAddress}`}
          target="_blank"
          rel="noopener noreferrer"
          className="dast-btn dast-btn-primary"
        >
          {intl.get("verify-account-button")}
        </a>
      </div>
    );
  };

  const updateAccountSection = () => (
    <div className="card card-body mb-6 content-box">
      <h4>{intl.get("update-your-information")} </h4>
      <div className="customer-information-form">
        <p>{intl.get("verify-account-paragraph")}</p>
        {mailToButton()}
      </div>
    </div>
  );

  const updateMarketingPreferences = (updatePayload: {
    [key: string]: string;
  }): void => {
    updateProfileSection(updatePayload, "preferences");
  };

  const renderAdminSection = () => {
    const adminsToRender =
      admins && admins.map(admin => admin._associatedetails[0]);
    return adminsToRender;
  };
  const filteredAdmins = renderAdminSection();

  const membershipDisplayName = (membershipToRender: string) => {
    const membershipName = membershipToRender.toLowerCase();
    const dcp = "dcp";
    const dcpDisplay = "Daikin Comfort Pro";
    const amanaAdv = "amanaadv";
    const amanaAdvDisplay = "Amana Advantage";
    const privateLabel = "privatelabel";
    const privateLabelDisplay = "Goodman Private Label";
    const aPlusPlatinum = "aplusplatinum";
    const aPlusPlatinumDisplay = "Goodman A-Plus Platinum";

    switch (membershipName) {
      case dcp:
        return dcpDisplay;
      case amanaAdv:
        return amanaAdvDisplay;
      case privateLabel:
        return privateLabelDisplay;
      case aPlusPlatinum:
        return aPlusPlatinumDisplay;
      default:
        return membershipToRender;
    }
  };

  if (!auth.isLoggedIn) return <Redirect to={{ pathname: "/" }} />;
  const loyaltyProgram = membership.map(member => {
    return (
      <>
        <div className="member-name">{membershipDisplayName(member)}</div>
        <span className="divider">, </span>
      </>
    );
  });
  return (
    <div className="profile-page-wrapper">
      {userProfile && !isInitialData ? (
        <>
          {showPageTitle ? <h2>{intl.get("account-settings")}</h2> : ""}
          <div className="row details columns-section">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="card card-body h-100 content-box">
                {filteredAdmins && filteredAdmins.length ? (
                  <div className="primary-user-info-container">
                    <h4>{intl.get("admin-information")}</h4>
                    <table className="admin-section-table">
                      {filteredAdmins.map(admin => {
                        return (
                          <tbody key={`${admin.firstName}-${admin.lastName}`}>
                            <tr className="admin-name-row">
                              <td colSpan={2}>
                                {admin["first-name"]} {admin["last-name"]}
                              </td>
                            </tr>
                            <tr className="admin-info-row">
                              <td className="email-label">
                                <span className="info-label">
                                  {intl.get("email")}:
                                </span>
                                <span>{admin.email}</span>
                              </td>
                              <td>
                                <span className="info-label">
                                  {intl.get("phone")}:
                                </span>
                                <span>{admin.phone}</span>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                ) : (
                  <>
                    <h4>{intl.get("admin-information")}</h4>
                    <p className="error-box">
                      {intl.get("no-admins-error-pt1")}
                      {chatEnabled ? (
                        <button
                          type="button"
                          onClick={openChat}
                          className="chat-launcher dast-link"
                        >
                          {intl.get("chat")}
                        </button>
                      ) : (
                        intl.get("chat")
                      )}
                      {intl.get("no-admins-error-pt2")}
                      <a
                        className="contact-email dast-link"
                        href={`mailto:${supportEmail}`}
                      >
                        {supportEmail}
                      </a>
                      .
                    </p>
                  </>
                )}

                <div className="sub-user-info-container">
                  <h4>{intl.get("user-information")}</h4>
                  <ProfileInfoMain
                    profileInfo={userInfo}
                    onChange={fetchProfileData}
                    history={history}
                    auth={auth}
                    userProfile={userProfile}
                    isPrimaryUser
                    editable
                  />
                </div>
              </div>
            </div>

            {ecommerceUser && (
              <div className="right-column-info col-lg-6 col-md-12 col-12">
                {config.accountVerificationEmail ? (
                  updateAccountSection()
                ) : (
                  <div className="card card-body mb-6 content-box">
                    <h4>{intl.get("customer-information-form")}</h4>
                    <div className="customer-information-form">
                      <p>{intl.get("download-verficiation-form")}</p>

                      <div className="dast-btn dast-btn-primary verification-form-card">
                        <RichText content={rtContent.value} />
                      </div>
                    </div>
                  </div>
                )}
                <div className="card card-body">
                  <h4>
                    <p>{intl.get("loyalty-program-title")}</p>
                  </h4>
                  <span className="membership">{loyaltyProgram}</span>
                </div>
                <div className="card card-body content-box">
                  {homeBranch && (
                    <DefaultBranchInfoCard
                      homeBranchNumber={homeBranch}
                      history={history}
                      auth={auth}
                      user={user}
                      account={account}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          {userMessage ? (
            <Messagecontainer
              message={{
                type: "error",
                debugMessages: userMessage
              }}
              closeContainerHandler={closeMessage}
            />
          ) : null}
          {ecommerceUser && (
            <>
              {" "}
              <div className="row details">
                <div className="col">
                  {userProfile.addresses ? (
                    <ProfileAddressesMain
                      addresses={userProfile.addresses[0]}
                      onChange={fetchProfileData}
                      onAddNewAddress={handleNewAddress}
                      onEditAddress={handleEditAddress}
                      setUserMessage={showMessage}
                      history={history}
                      auth={auth}
                      newAddressUri={newAddressUri}
                    />
                  ) : (
                    ""
                  )}
                  {renderNewAddressModal()}
                </div>
              </div>
              {config.GDPR.enable ? (
                <>
                  <div className="row details">
                    <div className="col-12">
                      <h2 className="section-title">{intl.get("gdpr")}</h2>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="card card-body" />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="profile-page-message-container">
                <MessageContainer
                  message={responseMessage.preferences}
                  closeContainerHandler={() => {
                    closeMessageContainer("preferences");
                  }}
                />
              </div>
              {config.showMarketingCommunications && (
                <div className="row details">
                  <div className="col">
                    {userProfile && (
                      <PreferencesContainer
                        userProfile={userProfile}
                        onSaveChanges={updateMarketingPreferences}
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="profile-page-message-container">
                <MessageContainer
                  message={responseMessage.notifications}
                  closeContainerHandler={() => {
                    closeMessageContainer("notifications");
                  }}
                />
              </div>
              <div className="row details">
                <div className="col">
                  <div>
                    {config.showUserNotifications &&
                      userProfile &&
                      (userProfile.optInSmsAlert === "true" ||
                        userProfile.optInSmsAlert === "false" ||
                        userProfile.optInSmsAlert === null) && (
                        <NotificationsContainer
                          userProfile={userProfile}
                          updateNotifications={updateNotifications}
                        />
                      )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : null}
    </div>
  );
};

export default ProfilePage;
