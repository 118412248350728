import React, { FC, useRef, useEffect, useState, useContext } from "react";
import {
  RouteComponentProps,
  withRouter,
  Redirect,
  useHistory
} from "react-router-dom";
import intl from "react-intl-universal";
import Modal from "react-responsive-modal";
import {
  cortexFetch,
  PaymentMethods,
  GeneralOrderInformation,
  getConfig,
  zoom,
  checkoutStarted,
  page,
  FulfillmentSection,
  PaymentSection,
  OrderSummarySection
} from "@zilker/store-components";
import {
  NotificationsStatus,
  NotificationsError
} from "@zilker/store-components/src/Notifications/Notifications";
import { MainContext } from "../contexts/MainContext";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace,
  formatQuoteAvailability
} from "../utils/helpers";
import { getAvailability } from "../services/connectGQLservices";
import {
  validateNotifications,
  validateGeneralOrderInformation,
  validateRequiredMethod
} from "../validation/checkoutValidation";

import "./CheckoutPage.less";
import "../theme/sharedClasses.less";

interface CheckoutPageFunctionalProps extends RouteComponentProps {
  match: any;
}

const initialOrderInformationErrors = {
  "po-number": "",
  date: "",
  time: "",
  phone: ""
};

enum JobAddressActions {
  CHANGE = "change",
  ADD_NEW = "new",
  CLOSE = ""
}

interface ClientEntity {
  name: string;
  id: string;
}

const defaultClient: ClientEntity = {
  name: "",
  id: ""
};

// If the showUserNotifications flag is set to false, then all notifications are disabled.
const disabledNotifications: NotificationsStatus = {
  smsChecked: false,
  orderStatusChecked: false,
  deliveryStatusChecked: false
};

const CheckoutPageFunctional: FC<CheckoutPageFunctionalProps> = ({ match }) => {
  const {
    params: { kinpayPrid }
  } = match;

  const { config } = getConfig();

  const context = useContext<{
    auth: any;
    order: any;
    user: any;
    cart: any;
    job: any;
    branches: any;
    navigation: any;
    contract: any;
    account: any;
  }>(MainContext);
  const {
    user: { userProfile },
    auth,
    cart: {
      selectedPayment,
      orderInformation,
      selectedClient,
      setClient,
      setNotificationStatus,
      notificationsStatus,
      setPaymentMethodError,
      setNotificationData,
      notificationsData,
      setCashUserError,
      setShippingOptionsArray,
      setSelectedShipping,
      setShippingAddressSelected,
      selectedShippingAddress,
      setShippingAddressesArray,
      setPendingAddress,
      setOrderInfoErrors,
      cartDetails: { defaultCart }
    },
    account: { accountDetails }
  } = context;

  const history = useHistory();

  const [orderData, setOrderData] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openAddressModal, setOpenAddressModal] = useState<boolean>(false);
  const [addressUrl, setAddressUrl] = useState<any>(undefined);
  const [deliveryLoading, setDeliveryLoading] = useState<boolean>(false);
  const [kinpayRedirectPrid, setKinpayRedirectPrid] = useState<string>(
    kinpayPrid
  );
  const [disableCompleteOrder, setDisableCompleteOrder] = useState<boolean>(
    true
  );
  const [cartItems, setCartItems] = useState<any>(undefined);
  const [cartDataFetched, setCartDataFetched] = useState<any>(undefined);
  const [missingFields, setMissingFields] = useState<string>("");
  const [notificationsErrors, setNotificationsErrors] = useState<
    NotificationsError
  >(undefined);
  const [backOrdered, setBackOrdered] = useState<boolean>(undefined);
  const [openJobAddressModal, setOpenJobAddressModal] = useState<
    JobAddressActions
  >(JobAddressActions.CLOSE);
  const [jobNumber, setJobNumber] = useState<any>(null);
  const [jobName, setJobName] = useState<any>(null);
  const [newAddressUri, setNewAddressUri] = useState<string>("");
  const [currentBranch, setCurrentBranch] = useState<number>(null);

  const generaOrderInformationRef = useRef<HTMLDivElement>();
  const fulfillmentRef = useRef<HTMLDivElement>();
  const billingRef = useRef<HTMLDivElement>();

  const generateShippingOptions = (orderDataProp: any): Array<any> => {
    const {
      account: {
        accountDetails: { customerRoles }
      }
    } = context;

    const BRANCHES_VIRTUAL = intl.get("virtual-branches");
    const isVirtualBranchUser =
      customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

    const shippingOptionsArray = [];
    // PGL-364: Updates to MCB50YSAU and MCKB70YSAU
    const skus =
      defaultCart &&
      defaultCart.items &&
      defaultCart.items.map(item => item._item[0]._code[0].code);
    const hasSpecialAirPurifiers =
      skus && skus.find(sku => sku === "MCB50YSAU" || sku === "MCKB70YSAU");
    if (
      orderDataProp._order[0]._deliveries &&
      orderDataProp._order[0]._deliveries[0]._element[0]._shippingoptioninfo
    ) {
      const shippingOption =
        orderDataProp._order[0]._deliveries[0]._element[0]
          ._shippingoptioninfo[0]._shippingoption;
      if (shippingOption) {
        const [description] = shippingOption;
        description.checked = true;
        shippingOptionsArray.push(description);
      }
      const selector =
        orderDataProp._order[0]._deliveries[0]._element[0]
          ._shippingoptioninfo[0]._selector;
      if (selector && selector[0]._choice) {
        const choices = selector[0]._choice;
        choices.map(choice => {
          const [description] = choice._description;
          description.selectaction = choice.links.find(
            link => link.rel === "selectaction"
          ).uri;
          description.checked = false;
          shippingOptionsArray.push(description);
          return description;
        });
      }
    }

    // Sort the shipping options
    const pickupOpt = shippingOptionsArray.find(elem =>
      elem.name.includes("Pickup")
    );
    const branchTruckOpt = shippingOptionsArray.find(elem =>
      elem.name.includes("Branch_Truck")
    );

    if (pickupOpt && branchTruckOpt) {
      if (isVirtualBranchUser || hasSpecialAirPurifiers) {
        return [branchTruckOpt];
      }
      return [pickupOpt, branchTruckOpt];
    }

    return shippingOptionsArray;
  };

  const generateShippingAddresses = (orderDataProp): Array<any> => {
    const shippingAddressesArray = [];

    if (
      orderDataProp._order[0]._deliveries &&
      orderDataProp._order[0]._deliveries[0]._element[0]._destinationinfo
    ) {
      const destination =
        orderDataProp._order[0]._deliveries[0]._element[0]._destinationinfo[0]
          ._destination;
      if (destination) {
        const [description] = destination;
        description.checked = true;
        // Keep uri of the address so we can filter out the default shipping address.
        description.uri = destination[0].self.uri;

        shippingAddressesArray.push(description);
      }
      const selector =
        orderDataProp._order[0]._deliveries[0]._element[0]._destinationinfo[0]
          ._selector;

      if (selector) {
        const choices = selector[0]._choice;
        choices.map(choice => {
          const [description] = choice._description;
          description.selectaction = choice.links.find(
            link => link.rel === "selectaction"
          ).uri;
          description.checked = false;
          // Keep uri of the address so we can filter out the default shipping address.
          description.uri = choice._description[0].self.uri;

          shippingAddressesArray.push(description);
          return description;
        });
      }
    }

    return shippingAddressesArray;
  };

  const fetchOrderData = async (newUri?: string) => {
    const { zoomFetchOrder } = zoom;
    const {
      auth: { logout, isLoggedIn }
    } = context;

    await cortexFetch(`/?zoom=${zoomFetchOrder.sort().join()}`)
      .then(res => checkResponse(res))
      .then(res => {
        const shippingOptionsArray = generateShippingOptions(
          res._defaultcart[0]
        );

        const shippingAddressesArray = generateShippingAddresses(
          res._defaultcart[0]
        );

        const currentSelectedShippingOption = shippingOptionsArray.find(
          opt => opt.checked
        );

        if (newUri) {
          // Send request to select newly created address as delivery (shipping) address for the order.
          const newAddress = shippingAddressesArray.find(
            address => address.self.uri === newUri
          );
          const newAddressSelect = newAddress.selectaction;

          setDeliveryLoading(true);

          try {
            if (isLoggedIn) {
              cortexFetch(newAddressSelect, {
                method: "post"
              })
                .then(response => {
                  const onSuccess = data => data;
                  return checkResponse(response, onSuccess);
                })
                .then(() => fetchOrderData())
                .then(() => setDeliveryLoading(false))

                .catch(e => {
                  setDeliveryLoading(false);
                  if (checkTokensExpired(e)) {
                    logout().catch(err =>
                      pushToMaintenace(history, {
                        e: err,
                        errIn:
                          "Logout => handleChange => CheckoutPageFunctional.tsx"
                      })
                    );
                  } else {
                    pushToMaintenace(history, {
                      e,
                      errIn: "handleChange => CheckoutPageFunctional.tsx"
                    });
                  }
                });
            }
          } catch (e) {
            setDeliveryLoading(false);
            pushToMaintenace(history, {
              e,
              errIn: "handleChange => CheckoutPageFunctional.tsx"
            });
          }
        }

        const currentSelectedShippingAddress = shippingAddressesArray.find(
          address => address.checked
        );

        if (res && res._defaultcart && res._defaultcart[0]._order) {
          setOrderData(res._defaultcart[0]);
          setIsLoading(false);
          setShippingOptionsArray(shippingOptionsArray);
          setShippingAddressesArray(shippingAddressesArray);
          setSelectedShipping(currentSelectedShippingOption);
          setShippingAddressSelected(currentSelectedShippingAddress);

          const orderUri = res._defaultcart[0]._order[0].self.uri;

          const {
            cortexApi: { scope }
          } = config;
          const orderId = orderUri.slice(
            orderUri.lastIndexOf(`${scope}/`) + `${scope}/`.length
          );

          // sends information to Segment when user starts the checkout flow
          checkoutStarted(orderId);
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          setIsLoading(false);
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => fetchOrderData => CheckoutPageFunctional.tsx"
            })
          );
        } else {
          setIsLoading(false);
          pushToMaintenace(history, {
            e,
            errIn: "fetchOrderData => CheckoutPageFunctional.tsx"
          });
        }
      });
  };

  const handleChange = async link => {
    const {
      auth: { isLoggedIn, logout }
    } = context;

    setDeliveryLoading(true);

    try {
      if (isLoggedIn) {
        await cortexFetch(link, {
          method: "post"
        })
          .then(res => {
            const onSuccess = data => data;
            return checkResponse(res, onSuccess);
          })
          .then(() => fetchOrderData())
          .then(() => setDeliveryLoading(false))

          .catch(e => {
            setDeliveryLoading(false);
            if (checkTokensExpired(e)) {
              logout().catch(err =>
                pushToMaintenace(history, {
                  e: err,
                  errIn: "Logout => handleChange => CheckoutPageFunctional.tsx"
                })
              );
            } else {
              pushToMaintenace(history, {
                e,
                errIn: "handleChange => CheckoutPageFunctional.tsx"
              });
            }
          });
      }
    } catch (e) {
      setDeliveryLoading(false);
      pushToMaintenace(history, {
        e,
        errIn: "handleChange => CheckoutPageFunctional.tsx"
      });
    }
  };

  const updateJobState = (currentJobNumber, currentJobName) => {
    setJobNumber(currentJobNumber);
    setJobName(currentJobName);

    fetchOrderData();
  };

  const updateCartState = (cartItemsArray, cartData) => {
    setCartItems(cartItemsArray);
    setCartDataFetched(cartData);
  };

  const newAddress = () => {
    setOpenAddressModal(true);
    setAddressUrl(undefined);
  };

  const editAddress = addressLink => {
    setOpenAddressModal(true);
    setAddressUrl({ address: addressLink });
  };

  const formatDate = (date: string): string => {
    let newDate = "";
    if (date) {
      newDate = `${date} 09:00`;
    }
    return newDate;
  };

  const editOrderDetails = () => {
    const {
      order: { setPoNumber },
      user: { getUserProfile },
      cart: { cartOrderDetails, orderInformationErrors, selectedPaymentUri },
      auth: { logout }
    } = context;

    const {
      phoneNumbers,
      orderStatusEmails,
      deliveryStatusEmail
    } = notificationsData;

    const {
      smsChecked,
      orderStatusChecked,
      deliveryStatusChecked
    } = notificationsStatus;

    const link = cartOrderDetails._cartorderdetailsform[0].links[0].uri;

    const payload = {
      ...orderInformation,
      "date-needed": formatDate(orderInformation.date),
      "receive-sms-alerts": smsChecked,
      "receive-order-status-emails": orderStatusChecked,
      "receive-delivery-status-email": deliveryStatusChecked,
      "sms-phone-numbers": smsChecked
        ? phoneNumbers.filter(phoneNumber => phoneNumber).join("|")
        : "",
      "order-status-emails": orderStatusChecked
        ? orderStatusEmails.filter(email => email).join("|")
        : "",
      "delivery-status-emails": deliveryStatusChecked
        ? deliveryStatusEmail
        : "",
      "ship-complete":
        config.showShipComplete && backOrdered
          ? orderInformation["ship-complete"]
          : null,
      "client-name": selectedClient.name,
      "client-id": selectedClient.id
    };

    setPoNumber(orderInformation["po-number"]);

    return cortexFetch(link, {
      method: "post",
      body: JSON.stringify(payload)
    })
      .then(res => {
        if (res.status === 400) {
          return res.json();
        }
        return res;
      })
      .then(res => {
        const onSuccess = data => data;
        return checkResponse(res, onSuccess);
      })
      .then(() => getUserProfile())
      .then(() => {
        const selectedPaymentBody = selectedPayment
          .toLowerCase()
          .replace("_", "-");

        const paymentPayload = {
          "limit-amount": "0",
          "payment-instrument-identification-form": {
            [selectedPaymentBody]: selectedPaymentBody,
            "display-name": selectedPayment
          }
        };

        const paymentLink = `/paymentinstruments${selectedPaymentUri}/paymentinstrument/form?followlocation&format=standardlinks`;
        cortexFetch(paymentLink, {
          method: "post",
          body: JSON.stringify(paymentPayload)
        })
          .then(res => {
            if (res.status === 400) {
              return res.json();
            }
            return res;
          })
          .then(res => {
            const onSuccess = data => data;
            return checkResponse(res, onSuccess);
          })
          .catch(e => {
            console.error(e);
            if (checkTokensExpired(e)) {
              logout().catch(err =>
                pushToMaintenace(history, {
                  e: err,
                  errIn:
                    "Logout => editOrderDetails => CheckoutPageFunctional.tsx"
                })
              );
            }
          });
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => editOrderDetails => CheckoutPageFunctional.tsx"
            })
          );
        }
        setOrderInfoErrors({
          "po-number": undefined
        });
        if (e.messages) {
          e.messages.map(message => {
            const field = message.data["field-name"]
              .split(/(?=[A-Z])/)
              .join("-")
              .toLowerCase();

            setOrderInfoErrors({
              ...orderInformationErrors,
              [field]: message.id
            });

            return message;
          });
        }
      });
  };

  const reviewOrder = () => {
    history.push("/order");
  };

  const handleCloseAddressModal = () => {
    setOpenAddressModal(false);
  };

  /**
   * @description Compare the selected job name and number (populated on the default cart) with the
   * job name and number from the currently selected shipping address. If the job name or the number are the same,
   * and the user tries to change the address, the warning popup will be displayed.
   */
  const isAddressAssociatedWithJob = (): boolean => {
    // Default cart has selected job number and name.
    const cartJobNumber = defaultCart ? defaultCart.jobNumber : null;
    const cartJobName = defaultCart ? defaultCart.jobName : null;

    // Job number and name associated with selected shipping address.
    const selectedShippingAddressJobName = selectedShippingAddress
      ? selectedShippingAddress["job-name"]
      : null;
    const selectedShippingAddressJobNumber = selectedShippingAddress
      ? selectedShippingAddress["job-number"]
      : null;

    let addressHasJobAssociated = false;

    // Check if selected shipping address has the same job as default cart.
    if (selectedShippingAddressJobName && selectedShippingAddressJobNumber) {
      addressHasJobAssociated =
        selectedShippingAddressJobName === cartJobName ||
        selectedShippingAddressJobNumber === cartJobNumber;
    }

    return addressHasJobAssociated;
  };

  /**
   * ## controlJobAddressModal
   * @param flag enum JobAddressActions
   * @description The values in enum control if the modal is going to be opened or closed;
   * and if it is opened - the action that is triggered on "Continue" click.
   * The enum has values for scenarios when the user wants to change the address, create a new
   * address, and just close the modal.
   */

  const controlJobAddressModal = (flag: JobAddressActions) => {
    setOpenJobAddressModal(flag);
  };

  const checkBackOrderedItems = () => {
    const {
      auth: { logout },
      branches: { branchesList },
      account: {
        accountDetails: { customerNumber }
      }
    } = context;

    if (config.showShipComplete && defaultCart && branchesList) {
      const skus =
        defaultCart.items &&
        defaultCart.items.map(item => item._item[0]._code[0].code);
      const { selectedBranch } = defaultCart;
      const branchNumber = selectedBranch.code;

      getAvailability(skus, customerNumber, branchNumber)
        .then(res => {
          if (res && res.data && res.data.data && res.data.data.inventory) {
            if (!res.data.data.inventory.entitledInventory) {
              console.error("Inventory error");
            } else {
              const data = res.data.data.inventory.entitledInventory;
              const availability = formatQuoteAvailability(
                data.branches,
                data.regionRollups,
                skus
              );
              const backOrderedArray = defaultCart.items.map(item => {
                const inventoryItem = availability.find(
                  ia =>
                    ia.sku === item._item[0]._code[0].code &&
                    ia.branchNumber === item["branch-number"]
                );
                const backOrderedItem = inventoryItem
                  ? inventoryItem.branchAvailability < item.quantity
                  : true;

                return backOrderedItem;
              });
              setBackOrdered(backOrderedArray.includes(true));
            }
          }
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn:
                  "Logout => checkBackOrderedItems => CheckoutPageFunctional.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "checkBackOrderedItems => CheckoutPageFunctional.tsx"
            });
          }
        });
    }
  };

  const disableCompletingOrder = () => {
    setDisableCompleteOrder(true);
  };

  const closeTostMessage = () => {
    setKinpayRedirectPrid("");
  };

  const resetMissingFields = () => {
    setMissingFields("");
  };

  const formatErrorMessageString = (
    generalOrderInformation: Array<string>,
    notifications: Array<any>,
    selectedPaymentMethod: string,
    cashOnlyErrorMessage: string
  ): string => {
    const reduceErrorMessage = (
      errors: Array<string>,
      initialMessage: string
    ) =>
      errors.reduce((result, current) => {
        if (current) {
          return !result ? `${current}\n` : `${result}${current}\n`;
        }
        return "";
      }, initialMessage);

    let errorMessage: string;
    errorMessage = reduceErrorMessage(generalOrderInformation, "");
    errorMessage = notifications.reduce((result: string, current: any) => {
      if (typeof current === "string" && current.length) {
        return !result ? `${current}\n` : `${result}${current}\n`;
      }
      return current.length ? reduceErrorMessage(current, result) : result;
    }, errorMessage);
    errorMessage = selectedPaymentMethod
      ? `${errorMessage}${selectedPaymentMethod}\n`
      : errorMessage;
    errorMessage = cashOnlyErrorMessage
      ? `${errorMessage}${cashOnlyErrorMessage}\n`
      : errorMessage;

    return errorMessage;
  };

  const scrollToErrorSection = ref => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const validateCheckoutForm = () => {
    const {
      user: {
        userProfile: { email, phone }
      },
      account: {
        accountDetails: { poNumberRequired, creditLineAllowed }
      }
    } = context;

    const phoneNumberRequired =
      email &&
      (!phone ||
        phone === "9999999999" ||
        phone === "999-999-9999" ||
        orderInformation.phone);

    const generalOrderInformationValidation = validateGeneralOrderInformation(
      orderInformation,
      poNumberRequired,
      phoneNumberRequired
    );
    const hasCashUserOnlyError =
      selectedPayment === PaymentMethods.creditLine &&
      creditLineAllowed === "false";

    const disabledNotificationsValidation = {
      isValid: true,
      errors: {
        deliveryStatusEmail: "",
        orderStatusEmails: [],
        phoneNumbers: []
      }
    };

    // Do not validate notifications fields if user notifications are hidden with setting showUserNotifications to false.
    const notificationsValidation = config.showUserNotifications
      ? validateNotifications(notificationsData, notificationsStatus)
      : disabledNotificationsValidation;

    const selectedPaymentMethodValidation = validateRequiredMethod(
      selectedPayment,
      "payment-method"
    );
    const cashOnlyErrorMessage = intl.get("cash-only-error");

    // Format error message that is displayed in the sidebar
    // Error message is concatenation of all error messages
    // (orderInformationDataValidation.errors + notificationsValidation.errors + selectedPaymentValidation.errors)
    const errorMessage: string = formatErrorMessageString(
      Object.values(generalOrderInformationValidation.errors),
      Object.values(notificationsValidation.errors),
      selectedPaymentMethodValidation.errors,
      hasCashUserOnlyError ? cashOnlyErrorMessage : ""
    );

    setOrderInfoErrors(
      generalOrderInformationValidation.isValid
        ? initialOrderInformationErrors
        : generalOrderInformationValidation.errors
    );
    setNotificationsErrors(
      notificationsValidation.isValid ? null : notificationsValidation.errors
    );
    setPaymentMethodError(selectedPaymentMethodValidation.errors);
    setMissingFields(errorMessage);
    setCashUserError(hasCashUserOnlyError ? cashOnlyErrorMessage : "");

    if (
      !generalOrderInformationValidation.isValid ||
      !notificationsValidation.isValid
    ) {
      scrollToErrorSection(generaOrderInformationRef);
    } else if (
      !selectedPaymentMethodValidation.isValid ||
      hasCashUserOnlyError
    ) {
      scrollToErrorSection(billingRef);
    }

    return (
      generalOrderInformationValidation.isValid &&
      notificationsValidation.isValid &&
      selectedPaymentMethodValidation.isValid &&
      !hasCashUserOnlyError
    );
  };

  const onCompleteOrder = () => {
    if (validateCheckoutForm()) {
      editOrderDetails().then(() => reviewOrder());
    }
  };

  const handleNotificationsStatusChange = name => {
    setNotificationStatus(prevStatus => ({
      ...prevStatus,
      [name]: !prevStatus[name]
    }));
    setNotificationsErrors(null);
  };

  const handleNotificationsDataChange = (name, value) => {
    setNotificationData(prevData => ({
      ...prevData,
      [name]: value
    }));
    setNotificationsErrors(null);
  };

  const renderJobAddressModal = () => {
    const {
      cart: { pendingShippingAddress }
    } = context;
    const changeAddress = openJobAddressModal === JobAddressActions.CHANGE;
    const newAddressConst = openJobAddressModal === JobAddressActions.ADD_NEW;

    const closeModal = () => {
      setOpenJobAddressModal(JobAddressActions.CLOSE);
      setPendingAddress(null);
    };

    let confirmHandler = () => {};

    if (changeAddress) {
      // If the user is changing address via radio button.
      confirmHandler = () => {
        if (pendingShippingAddress) {
          // Make API call to save address
          setShippingAddressSelected(pendingShippingAddress);
          setOpenJobAddressModal(JobAddressActions.CLOSE);
          setPendingAddress(null);
        }
      };
    } else if (newAddressConst) {
      // If the user is creating a new address.
      confirmHandler = () => {
        closeModal();
        newAddress();
      };
    }

    const modal = (
      <Modal
        open={changeAddress || newAddressConst}
        onClose={closeModal}
        classNames={{ modal: "cart-selection-modal-content" }}
      >
        <div className="modal-lg job-address-modal">
          <div className="modal-content" id="simplemodal-container">
            <div className="modal-header">
              <h2 className="modal-title">{intl.get("info")}</h2>
            </div>
            <div className="modal-body">
              <div id="cart_selection_modal_form">
                <p>{intl.get("job-address-info")}</p>
                <div className="action-row">
                  <div className="form-input btn-container new-address-modal-container">
                    <button
                      aria-label={intl.get("continue")}
                      type="button"
                      className="dast-btn dast-btn-primary wide"
                      onClick={confirmHandler}
                    >
                      {intl.get("continue")}
                    </button>
                    <button
                      aria-label={intl.get("cancel")}
                      type="button"
                      className="dast-btn dast-btn-secondary wide"
                      onClick={closeModal}
                    >
                      {intl.get("cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );

    return modal;
  };

  const setNewUriAddress = (newUri: string): void => {
    if (newUri) {
      setNewAddressUri(newUri);
    }
  };

  const setBranchCurrent = (branchNumber: number): void => {
    setCurrentBranch(branchNumber);
    checkBackOrderedItems();
  };

  const renderSelectedClient = (): any => {
    const {
      user: {
        userProfile: { clientList }
      }
    } = context;

    return clientList && clientList.length ? (
      <div className="content-box client-list-wrapper">
        <h4>{intl.get("selected-client")}</h4>

        <label htmlFor="client-dropdown" className="label">
          {`${intl.get("ordering-on-behalf")} `}
          <span>*</span>
        </label>
        <span>{`${selectedClient.name} ${selectedClient.id}`}</span>
      </div>
    ) : null;
  };

  const openChat = () => window.zE.activate();

  useEffect(() => {
    checkBackOrderedItems();
  }, [currentBranch]);

  useEffect(() => {
    const {
      cart: {
        cartDetails: { totalCount, cartDataFetched: dataFetched }
      }
    } = context;

    const currentJobNumber = defaultCart ? defaultCart.jobNumber : null;
    const currentJobName = defaultCart ? defaultCart.jobName : null;

    const { clientList } = userProfile;
    const clientFromList =
      clientList && clientList.length
        ? { name: clientList[0].name, id: clientList[0].id }
        : defaultClient;
    const currentSelectedClient: ClientEntity = defaultCart
      ? { id: defaultCart.clientId, name: defaultCart.clientName }
      : clientFromList;

    const selectedBranch = defaultCart ? defaultCart.selectedBranch : {};

    setCartItems(totalCount);
    setCartDataFetched(dataFetched);
    setJobName(currentJobName);
    setJobNumber(currentJobNumber);
    setClient(currentSelectedClient);
    setCurrentBranch(selectedBranch.code);

    checkBackOrderedItems();

    if (
      kinpayRedirectPrid &&
      kinpayRedirectPrid === sessionStorage.getItem("kinpayPrid")
    ) {
      setDisableCompleteOrder(false);
    }

    page();

    return () => {
      sessionStorage.removeItem("kinpayPrid");
    };
  }, []);

  useEffect(() => {
    const {
      cart: {
        cartDetails: { totalCount, cartDataFetched: dataFetched }
      },
      branches: { branchesList }
    } = context;

    if (
      (dataFetched !== cartDataFetched || totalCount !== cartItems) &&
      defaultCart &&
      branchesList
    ) {
      updateCartState(totalCount, dataFetched);
    }
  }, [cartDataFetched, cartItems]);

  useEffect(() => {
    const currentJobNumber = defaultCart ? defaultCart.jobNumber : null;
    const currentJobName = defaultCart ? defaultCart.jobName : null;
    if (jobName !== currentJobName || jobNumber !== currentJobNumber) {
      updateJobState(currentJobNumber, currentJobName);
    }
  }, [jobName, jobNumber]);

  useEffect(() => {
    if (defaultCart && selectedClient.id !== defaultCart.clientId) {
      setClient({
        id: defaultCart.clientId,
        name: defaultCart.clientName
      });
    }
  }, [selectedClient]);

  useEffect(() => {
    const {
      branches: { branchesList }
    } = context;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : {};
    if (
      defaultCart &&
      branchesList &&
      (!currentBranch || selectedBranch.code !== currentBranch)
    ) {
      setBranchCurrent(selectedBranch.code);
      fetchOrderData();
    }
  }, [currentBranch]);

  // Prevent user from seeing the checkout page if the cart is empty.
  const kinpayRedirectPridState = sessionStorage.getItem("kinpayPrid");

  useEffect(() => {
    fetchOrderData();
  }, []);

  return (
    <>
      {!kinpayRedirectPridState && cartDataFetched && cartItems === 0 && (
        <Redirect to="/" />
      )}
      {!isLoading && orderData && !userProfile.isInitialProfile ? (
        <div className="checkout-container-new container-fluid d-flex flex-column flex-grow-1">
          {renderJobAddressModal()}
          <div className="container d-flex flex-column flex-grow-1">
            <div className="row flex-grow-1">
              <div className="col-12 d-flex flex-xl-row flex-column">
                <div className="checkout-body flex-fill">
                  <div
                    className="content-box general-order-information"
                    ref={generaOrderInformationRef}
                  >
                    <h4 className="section-title general-order-information-title">
                      {intl.get("general-order-information")}
                    </h4>
                    <GeneralOrderInformation
                      poNumberRequired={
                        config.POAlwaysRequired
                          ? true
                          : accountDetails.poNumberRequired === "true"
                      }
                      handleNotificationsStatusChange={
                        handleNotificationsStatusChange
                      }
                      handleNotificationsDataChange={
                        handleNotificationsDataChange
                      }
                      notificationsErrors={notificationsErrors}
                    />
                  </div>

                  {config.showClientInformation &&
                    orderData &&
                    renderSelectedClient()}

                  <div className="content-box fulfillment" ref={fulfillmentRef}>
                    {defaultCart && (
                      <FulfillmentSection
                        deliveryLoading={deliveryLoading}
                        newAddressUri={newAddressUri}
                        orderData={orderData}
                        editAddress={editAddress}
                        handleChange={handleChange}
                        isAddressAssociatedWithJob={isAddressAssociatedWithJob}
                        controlJobAddressModal={controlJobAddressModal}
                        newAddress={newAddress}
                        backOrdered={backOrdered}
                      />
                    )}
                  </div>
                  <div className="content-box billing" ref={billingRef}>
                    {defaultCart && (
                      <PaymentSection
                        orderData={orderData}
                        openAddressModal={openAddressModal}
                        addressUrl={addressUrl}
                        disableCompleteOrder={disableCompleteOrder}
                        missingFields={missingFields}
                        handleCloseAddressModal={handleCloseAddressModal}
                        fetchOrderData={fetchOrderData}
                        setNewAddressUri={setNewUriAddress}
                        openChat={openChat}
                        validateCheckoutForm={validateCheckoutForm}
                        editOrderDetails={editOrderDetails}
                        disableCompletingOrder={disableCompletingOrder}
                        closeTostMessage={closeTostMessage}
                        resetMissingFields={resetMissingFields}
                        disabledNotifications={disabledNotifications}
                      />
                    )}
                  </div>
                </div>
                <div className="checkout-sidebar">
                  <OrderSummarySection
                    orderData={orderData}
                    missingFields={missingFields}
                    disableCompleteOrder={disableCompleteOrder}
                    onCompleteOrder={onCompleteOrder}
                    resetMissingFields={resetMissingFields}
                    fetchOrderData={() => {
                      fetchOrderData();
                    }}
                    history={history}
                    auth={auth}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="checkout-container container-fluid">
          <div className="checkout-container-inner container">
            <div
              data-region="checkoutTitleRegion"
              className="checkout-title-container"
              style={{ display: "block" }}
            >
              <div>
                <h1 className="view-title">{intl.get("checkout-summary")}</h1>
              </div>
            </div>
            <div className="checkout-main-container">
              <div className="loader" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(CheckoutPageFunctional);
