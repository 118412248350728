import React, { useState, useEffect, FC, useRef, useContext } from "react";
import intl from "react-intl-universal";

import { InfoCardComponent, page, getConfig } from "@zilker/store-components";

import { getCreditInformationGQL } from "../services/connectGQLservices";

import "./CreditInformation.less";
import { checkTokensExpired, pushToMaintenace } from "../utils/helpers";
import { MainContext } from "../contexts/MainContext";

interface CreditInformationInterface {
  creditLimit: number;
  due30Days: number;
  due60Days: number;
  due90Days: number;
  due120Days: number;
  lastPayDate: string;
  lastPayAmt: number;
  average6MonthSales: number;
  last12MonthSales: number;
  currentBalanceDue: number;
  totalBalanceDue: number;
}

interface CreditInformationProps {
  history: any;
  auth: any;
}

const CreditInformation: FC<CreditInformationProps> = ({ history, auth }) => {
  const [creditInformation, setCreditInformation] = useState<
    CreditInformationInterface
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const context = useContext<{ user: any; account: any }>(MainContext);
  const {
    account: {
      accountDetails: { customerNumber }
    }
  } = context;

  const { config } = getConfig();
  const { supportEmail, chatEnabled } = config;

  const isCancelled = useRef(false);

  const formatCurrency = value => {
    if (value || value === 0) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(value);
    }
    return "";
  };

  useEffect(() => {
    page();
    getCreditInformationGQL(customerNumber)
      .then(res => {
        if (
          !isCancelled.current &&
          res &&
          res.data &&
          res.data.data &&
          res.data.data.customer &&
          res.data.data.customer.accountHistory
        ) {
          setCreditInformation(res.data.data.customer.accountHistory);
          setLoading(false);
        }
      })
      .catch(e => {
        if (!isCancelled.current) {
          setLoading(false);
        }
        if (checkTokensExpired(e)) {
          auth.logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => getCreditInformation => CreditInformation.tsx"
            })
          );
        } else if (isCancelled.current) {
          pushToMaintenace(history, {
            e,
            errIn: "getCreditInformation => CreditInformation.tsx"
          });
        }
      });
    // eslint-disable-next-line no-return-assign
    return () => (isCancelled.current = true);
  }, []);

  const triggerChatPopUp = () => {
    const zendeskChatIframe = document.getElementById(
      "launcher"
    ) as HTMLIFrameElement;

    if (zendeskChatIframe) {
      const iframeContentWindow = zendeskChatIframe.contentWindow;

      if (iframeContentWindow) {
        const chatLauncher = iframeContentWindow.document.querySelector(
          '[data-testid="launcher"]'
        );

        if (chatLauncher) {
          (chatLauncher as HTMLElement).click();
        } else {
          console.error(
            'There is no element with data-testid - "launcher" found'
          );
        }
      } else {
        console.error("Could not access the iframe content window");
      }
    } else {
      console.error("No iframe found with the specified id");
    }
  };

  return loading ? (
    <div className="loader" />
  ) : (
    <div className="credit-information credit-information-page-wrapper">
      {creditInformation ? (
        <>
          <div className="content-box">
            <h4>{`${intl.get("account-overview")}`}</h4>
            <div className="overview">
              <InfoCardComponent
                name={`${intl.get("credit-limit")}:`}
                value={formatCurrency(creditInformation.creditLimit)}
              />
            </div>
          </div>
          <div className="balance-due content-box">
            <h4>{`${intl.get("balance-due")}:`}</h4>
            <div className="balance-cards">
              <InfoCardComponent
                name={`${intl.get("current")}:`}
                value={formatCurrency(creditInformation.currentBalanceDue)}
              />
              <InfoCardComponent
                name={`30 ${intl.get("days")}:`}
                value={formatCurrency(creditInformation.due30Days)}
              />
              <InfoCardComponent
                name={`60 ${intl.get("days")}:`}
                value={formatCurrency(creditInformation.due60Days)}
              />
              <InfoCardComponent
                name={`90 ${intl.get("days")}:`}
                value={formatCurrency(creditInformation.due90Days)}
              />
              <InfoCardComponent
                name={`120 ${intl.get("days")}:`}
                value={formatCurrency(creditInformation.due120Days)}
              />
              <div className="total-due">
                <p>{`${intl.get("total-due")}`}</p>
                <h3>{formatCurrency(creditInformation.totalBalanceDue)}</h3>
              </div>
            </div>
          </div>
          <div className="summary content-box">
            <h4>{`${intl.get("account-summary")}`}</h4>
            <div className="summary-grid">
              <InfoCardComponent
                name={`${intl.get("last-payment-date")}:`}
                value={creditInformation.lastPayDate}
              />
              <InfoCardComponent
                name={`${intl.get("last-payment-amount")}:`}
                value={formatCurrency(creditInformation.lastPayAmt)}
              />
              <InfoCardComponent
                name={`${intl.get("average-monthly-sales")} (${intl.get(
                  "last-6-months"
                )})`}
                value={formatCurrency(creditInformation.average6MonthSales)}
              />
              <InfoCardComponent
                name={`${intl.get("last-12-months")} ${intl.get(
                  "total-sales"
                )}`}
                value={formatCurrency(creditInformation.last12MonthSales)}
              />
            </div>
          </div>
        </>
      ) : (
        <p className="error-box content-box">
          {intl.get("credit-information-error-pt1")}
          {chatEnabled ? (
            <button
              type="button"
              onClick={triggerChatPopUp}
              className="chat-launcher dast-link"
            >
              {intl.get("chat")}
            </button>
          ) : (
            intl.get("chat")
          )}
          {intl.get("credit-information-error-pt2")}
          <a
            className="contact-email dast-link"
            href={`mailto:${supportEmail}`}
          >
            {supportEmail}
          </a>
          .
        </p>
      )}
    </div>
  );
};

export default CreditInformation;
