import React, { FC, useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { BrComponentContext, BrPageContext } from "@bloomreach/react-sdk";
import { Document, Component } from "@bloomreach/spa-sdk";
import intl from "react-intl-universal";
import { History } from "history";

import {
  SavedOrderCard,
  page as StorePage,
  RichText
} from "@zilker/store-components";
import { getStandardOrderListGQL } from "../services/connectGQLservices";
import StandardOrderDetails from "./StandardOrderDetails";
import { useMainContext } from "../contexts/MainContext";

import "./SavedOrders.less";
import {
  checkTokensExpired,
  pushToMaintenace,
  decodeURIComponentSafely,
  getUniqueArray
} from "../utils/helpers";

interface StandardOrdersProps {
  history: History;
  match: any;
  auth: any;
}

interface OrderListFilters {
  offset: number;
  limit: number;
}

const StandardOrders: FC<StandardOrdersProps> = ({
  match: { params },
  history,
  auth: { logout }
}) => {
  const { detail } = params;
  const {
    account: {
      accountDetails: { customerNumber }
    }
  } = useMainContext();
  const standardOrdersLimit = 25;

  const initialFilters: OrderListFilters = {
    offset: 0,
    limit: standardOrdersLimit
  };

  const component = useContext(BrComponentContext) as any;
  const page = useContext(BrPageContext);

  const soRichTextComponent = component.model.children
    .map(child => page.getContent<Component>(child))
    .find(doc => doc.name === "standard_orders");

  let content = "";
  if (soRichTextComponent) {
    const { document: docRef } = soRichTextComponent.models;
    const soRichTextContent = page.getContent<Document>(docRef);
    const { content: docContent } = soRichTextContent.getData();
    content = page.rewriteLinks(docContent.value);
  }

  const [orderList, setOrderList] = useState<any>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [orderListError, setOrderListError] = useState<boolean>(false);
  const [orderListFilters, setOrderListFilters] = useState<OrderListFilters>(
    initialFilters
  );

  const { limit } = orderListFilters;

  const fetchStandardOrderList = filters => {
    if (customerNumber) {
      getStandardOrderListGQL(customerNumber, filters).then(res => {
        let standardOrderList = null;
        try {
          standardOrderList = res.data.data.customer.standardOrderList;
        } catch (e) {
          setShowLoader(false);
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => getStandardOrderListGQL => StandardOrders.tsx"
              })
            );
          } else {
            console.error(e);
            setOrderListError(e);
          }
        }
        const { offset } = filters;
        const concatedStandardOrderList =
          offset > 0
            ? getUniqueArray(orderList.concat(standardOrderList))
            : standardOrderList;
        setOrderList(concatedStandardOrderList);
        setShowLoader(false);
      });
    }
  };

  const onLoadMore = () => {
    setShowLoader(true);
    const offset = orderList ? orderList.length : 0;
    const newOrderListFilters: OrderListFilters = {
      ...orderListFilters,
      offset
    };
    setOrderListFilters(newOrderListFilters);
    fetchStandardOrderList(newOrderListFilters);
  };

  useEffect(() => {
    StorePage();
    setShowLoader(true);
    const offset = orderList ? orderList.length : 0;
    const newOrderListFilters: OrderListFilters = {
      ...orderListFilters,
      offset
    };
    fetchStandardOrderList(newOrderListFilters);
  }, [customerNumber]);

  if (orderListError) {
    return (
      <Redirect
        to={{
          pathname: "/maintenance",
          state: {
            error: {
              e: { message: orderListError }
            }
          }
        }}
      />
    );
  }

  const renderLoadMoreButton = () => {
    const shouldDisplayLoadMore =
      orderList && orderList.length && orderList.length % limit === 0;
    const loadMoreButton = !showLoader ? (
      <button
        type="button"
        className="dast-btn dast-btn-primary load-more-btn"
        onClick={onLoadMore}
        aria-label={intl.get("load-more")}
      >
        {intl.get("load-more")}
      </button>
    ) : (
      <div className="loader-container">
        <div className="miniLoader" />
      </div>
    );

    if (shouldDisplayLoadMore) {
      return loadMoreButton;
    }
    return null;
  };

  const decodedOrderName = decodeURIComponentSafely(detail);
  const standardOrder =
    orderList && orderList.find(order => order.name === decodedOrderName);

  return detail ? (
    <StandardOrderDetails
      orderName={decodedOrderName}
      order={standardOrder}
      history={history}
      logout={logout}
    />
  ) : (
    <div className="saved-orders content-box content-table">
      <h2>{intl.get("branch-standard-orders")}</h2>
      {orderList && orderList.length ? (
        <>
          <div className="saved-orders-labels">
            <span>{`${intl.get("name")}: `}</span>
            <span>{`${intl.get("actions")}: `}</span>
            <span>{`${intl.get("add-to-cart")}: `}</span>
          </div>
          <SavedOrderCard history={history} carts={orderList} />
        </>
      ) : (
        !showLoader && (
          <div className="no-standard-orders-to-display">
            <RichText content={content} />
          </div>
        )
      )}
      {renderLoadMoreButton()}
    </div>
  );
};

export default StandardOrders;
