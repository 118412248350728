/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import intl from "react-intl-universal";
import { useMainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import { getConfig } from "../utils/ConfigProvider";
import ZopimChat from "./ZopimChat";

const ZendeskWidget = () => {
  const context = useMainContext();
  const [widgetLoaded, setWidget] = useState(false);
  const { config } = getConfig();

  const {
    user: { userProfile },
    account: { accountDetails }
  } = context;

  const { givenName, email, familyName } = userProfile;
  const { customerNumber, customerRoles } = accountDetails;

  const BRANCHES_VIRTUAL = intl.get("virtual-branches");
  const isVirtualBranchUser =
    customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

  useEffect(() => {
    if (email && widgetLoaded && !config.useZendeskZopimChat) {
      let prefillValue = `${givenName} ${familyName} - ${customerNumber}`;
      let prefillEmail = email;

      if (isVirtualBranchUser) {
        ZendeskAPI("webWidget", "chat:addTags", "virtual_branch_user");
      }

      ZendeskAPI("webWidget", "prefill", {
        name: {
          value: prefillValue
        },
        email: {
          value: prefillEmail
        }
      });
    }
  }, [email, widgetLoaded]);

  if (config.useZendeskZopimChat) {
    return <ZopimChat zendeskKey={config.ZENDESK_KEY} />;
  }

  return (
    <Zendesk
      zendeskKey={config.ZENDESK_KEY}
      onLoaded={() => {
        console.error("Zendesk widget loaded");
        setWidget(true);
      }}
    />
  );
};

export default ZendeskWidget;
