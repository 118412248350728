import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BrProps } from "@bloomreach/react-sdk";
import intl from "react-intl-universal";
import {
  cortexFetch,
  B2bEditAssociate,
  page,
  getConfig,
  Messagecontainer
} from "@zilker/store-components";
import {
  checkResponse,
  checkMemberType
} from "@elasticpath/ref-store/src/utils/helpers";
import base32 from "@elasticpath/ref-store/src/utils/base32/base32";
import Modal from "react-responsive-modal";
import { fetchAccountAssociates } from "../../services/EpServices";
import { MainContext } from "../../contexts/MainContext";
import UserRoles from "../../utils/mappings/userRoles";

import "./AccountMain.less";

interface AccountMainProps extends RouteComponentProps, BrProps {
  account: any;
  // history: any;
  auth: any;
  user: any;
}

enum RoleTypes {
  BUYER = "BUYER",
  BUYER_ADMIN = "BUYER_ADMIN",
  CATALOG_BROWSER = "CATALOG_BROWSER",
  ECOMM_VIEW_CREDIT = "ECOMM_VIEW_CREDIT",
  ECOMM_VIEW_STATEMENTS = "ECOMM_VIEW_STATEMENTS",
  ECOMMERCE_BUYER = "ECOMMERCE_BUYER",
  LEAD_DASHBOARD_ADMIN = "LEADGEN_ADMIN",
  LEADGEN_DISTRIBUTOR_ADMIN = "LEADGEN_DISTRIBUTOR_ADMIN",
  LEAD_DASHBOARD_VIEWONLY = "LEADGEN_VIEWONLY",
  VIEW_DISTRIBUTOR_REPORTS = "VIEW_DISTRIBUTOR_REPORTS",
  VIEW_DEALER_REPORTS = "VIEW_DEALER_REPORTS"
}

interface AccountMainState {
  isLoading: boolean;
  isDeletingSubuser: boolean;
  isEditAssociateOpen: boolean;
  isApprovingUser: boolean;
  selector: string;
  associateEditEmail: string;
  associates: {
    [key: string]: any;
  };
  pendingAssociates: any;
  userEmail: string;
  isAddAssociateOpen: boolean;
  addAssociateUri: string;
  error: string;
  pendingUserApprovalError: string;
  deleteAssociateModalOpened: boolean;
  approveAssociateModalOpened: boolean;
  selectedAssociate: any;
  associate: any;
  accountAssociates: any;
}

class AccountMain extends React.Component<AccountMainProps, AccountMainState> {
  static contextType = MainContext;

  _isMounted = false;

  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      isDeletingSubuser: false,
      isEditAssociateOpen: false,
      isApprovingUser: false,
      isAddAssociateOpen: false,
      selector: "",
      associates: {},
      pendingAssociates: null,
      userEmail: "",
      associateEditEmail: "",
      addAssociateUri: "",
      error: "",
      pendingUserApprovalError: "",
      deleteAssociateModalOpened: false,
      approveAssociateModalOpened: false,
      selectedAssociate: undefined,
      associate: null,
      accountAssociates: null
    };

    this.handleAccountSettingsUpdate = this.handleAccountSettingsUpdate.bind(
      this
    );
    this.handleEditAssociateClicked = this.handleEditAssociateClicked.bind(
      this
    );
    this.handleDeleteSubuser = this.handleDeleteSubuser.bind(this);
    this.handleApproveAssociate = this.handleApproveAssociate.bind(this);
    this.handleAddAssociateClicked = this.handleAddAssociateClicked.bind(this);
    this.isEditAssociateClosed = this.isEditAssociateClosed.bind(this);
    this.getAccountData = this.getAccountData.bind(this);
    this.renderDesktopLayout = this.renderDesktopLayout.bind(this);
    this.renderMobileLayout = this.renderMobileLayout.bind(this);
    this.closeDeleteUserModal = this.closeDeleteUserModal.bind(this);
    this.closeApproveUserModal = this.closeApproveUserModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.getAssociates();
    page();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderAssociateRoles = roles => {
    try {
      return JSON.parse(roles).map(role => role.toUpperCase());
    } catch (e) {
      return [];
    }
  };

  getAssociates() {
    const { scope } = getConfig().config.cortexApi;
    const accountGuid = localStorage.getItem(`${scope}_AccountGuid`);

    fetchAccountAssociates(accountGuid).then(res => {
      if (
        res._associates &&
        res._associates[0] &&
        res._associates[0]._element
      ) {
        const associatesArray = res._associates[0]._element.map(associate => ({
          email: associate._associatedetails[0].email,
          firstName: associate._associatedetails[0]["first-name"],
          lastName: associate._associatedetails[0]["last-name"],
          phone: associate._associatedetails[0].phone,
          uri: associate._associatedetails[0].self.uri,
          status: associate.status,
          role: associate.role,
          roles: associate._associatedetails[0].roles
            ? this.renderAssociateRoles(associate._associatedetails[0].roles)
            : []
        }));
        this.getAccountData(associatesArray);

        this.setState({
          accountAssociates: associatesArray
        });
      }
    });
  }

  checkIfLeadGenAdmin = () => {
    const {
      user: { userProfile }
    } = this.context;
    const { roles } = userProfile;
    return (
      roles.includes(UserRoles().leadDashboardAdmin) ||
      roles.includes(UserRoles().leadDistributerAdmin)
    );
  };

  renderEditButton(isPendingSection, associate, isMobileLayout) {
    if (isPendingSection) {
      return (
        <button
          className={
            isMobileLayout ? "dast-btn dast-btn-secondary" : "ico-button"
          }
          type="button"
          onClick={() => this.openApproveUserModal(associate)}
        >
          <i className="icon-user-add" />
          <span>{intl.get("approve")}</span>
        </button>
      );
    }
    return (
      <button
        className={
          isMobileLayout ? "dast-btn dast-btn-secondary" : "ico-button"
        }
        type="button"
        onClick={() => this.handleEditAssociateClicked(associate.associate)}
      >
        <i className="icon-edit" />
        <span>{intl.get("edit")}</span>
      </button>
    );
  }

  getAccountData(associateUsers) {
    const {
      user: { userProfile },
      account: { accountDetails }
    } = this.context;

    const isLeadGenAdmin = this.checkIfLeadGenAdmin();
    const { addAssociateUri, isBuyerAdmin, loaded } = accountDetails;
    if (isBuyerAdmin || isLeadGenAdmin) {
      if (this._isMounted && addAssociateUri && associateUsers) {
        const pendingAssociateUsers = associateUsers.filter(
          user => user.status === "PENDING_APPROVAL"
        );

        const approvedAssociates = associateUsers.filter(
          user => user.status === "ACTIVE"
        );
        this.setState({
          associates: approvedAssociates.map(element => ({
            associate: {
              ...element,
              deleteUri: addAssociateUri
            },
            roles: element.roles
          })),
          pendingAssociates: pendingAssociateUsers
            ? pendingAssociateUsers.map(element => ({
                associate: {
                  ...element,
                  deleteUri: addAssociateUri
                },
                roles: element.roles
              }))
            : null,
          userEmail: userProfile.email,
          isLoading: false,
          addAssociateUri
        });
      }
    } else if (!isBuyerAdmin && !isLeadGenAdmin && accountDetails && loaded) {
      this.setState({
        isLoading: false
      });
    }
  }

  fetchAccountData = async () => {
    const {
      account: { getUserAccount }
    } = this.context;
    await getUserAccount();
  };

  handleAccountSettingsUpdate = async () => {
    const { accountAssociates } = this.state;

    await this.fetchAccountData();
    await this.getAssociates();

    this.getAccountData(accountAssociates);
  };

  handleEditAssociateClicked(associate) {
    if (this._isMounted) {
      this.setState({
        isEditAssociateOpen: true,
        associateEditEmail: associate.email,
        associate
      });
    }
  }

  handleAddAssociateClicked() {
    if (this._isMounted) {
      this.setState({ isAddAssociateOpen: true });
    }
  }

  handleApproveAssociate() {
    const { selectedAssociate } = this.state;
    const {
      associate: { uri, role, email }
    } = selectedAssociate;
    const approveUri = uri.slice(0, uri.lastIndexOf("/"));

    this.setState({
      isApprovingUser: true
    });

    cortexFetch(approveUri, {
      method: "put",
      body: JSON.stringify({
        role,
        status: "ACTIVE"
      })
    })
      .then(res => {
        if (res.status === 400) {
          return res.json();
        }
        return res;
      })
      .then(res => {
        const onSuccess = data => data;

        return checkResponse(res, onSuccess);
      })
      .then(() => {
        this.setState({
          approveAssociateModalOpened: false,
          isApprovingUser: false
        });
        this.handleAccountSettingsUpdate();
      })
      .catch(err => {
        if (this._isMounted) {
          this.setState({
            pendingUserApprovalError: intl.get("approve-subuser-error", {
              selectedAssociateEmail: email
            }),
            isApprovingUser: false,
            approveAssociateModalOpened: false
          });
        }
      });
  }

  handleDeleteSubuser(email: string) {
    const { scope } = getConfig().config.cortexApi;
    const { selectedAssociate } = this.state;
    const {
      associate: { deleteUri, status }
    } = selectedAssociate;
    const isPendingUser = status === "PENDING_APPROVAL";
    const url: string = `/deletesubuser/${scope}/form`;

    const accountguidencoded: string = deleteUri.slice(
      deleteUri.lastIndexOf(scope) + scope.length + 1
    );
    const accountguid = base32.decode(accountguidencoded);

    this.setState({
      isDeletingSubuser: true
    });

    cortexFetch(url, {
      method: "post",
      body: JSON.stringify({
        accountguid,
        email
      })
    })
      .then(res => {
        if (res.status === 400) {
          return res.json();
        }
        return res;
      })
      .then(res => {
        const onSuccess = data => data;

        return checkResponse(res, onSuccess);
      })
      .then(() => {
        this.setState({
          deleteAssociateModalOpened: false
        });
        this.handleAccountSettingsUpdate();
      })
      .catch(err => {
        if (this._isMounted) {
          this.setState({
            error: isPendingUser ? "" : err,
            pendingUserApprovalError: isPendingUser
              ? intl.get("delete-subuser-error", {
                  selectedAssociateEmail: email
                })
              : "",
            isDeletingSubuser: false,
            deleteAssociateModalOpened: false
          });
        }
      });
  }

  openDeleteUserModal(associate) {
    this.setState({
      deleteAssociateModalOpened: true,
      selectedAssociate: associate
    });
  }

  closeDeleteUserModal() {
    this.setState({
      deleteAssociateModalOpened: false
    });
  }

  openApproveUserModal(associate) {
    this.setState({
      approveAssociateModalOpened: true,
      selectedAssociate: associate
    });
  }

  closeApproveUserModal() {
    this.setState({
      approveAssociateModalOpened: false
    });
  }

  renderDeleteAssociateModal() {
    const { deleteAssociateModalOpened, selectedAssociate } = this.state;
    const associateEmail = selectedAssociate.associate.email;
    return (
      <Modal
        open={deleteAssociateModalOpened}
        onClose={this.closeDeleteUserModal}
      >
        <div className="modal-lg delete-associate-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2>{intl.get("delete-user")}</h2>
            </div>
            <div className="modal-body">
              <p>{intl.get("delete-user-confirmation")}</p>
              <div className="delete-associate-buttons">
                <button
                  type="button"
                  className="dast-btn dast-btn-secondary"
                  onClick={this.closeDeleteUserModal}
                >
                  {intl.get("cancel")}
                </button>
                <button
                  type="button"
                  className="dast-btn dast-btn-primary"
                  onClick={e => this.handleDeleteSubuser(associateEmail)}
                >
                  <i className="icon-delete" />
                  {intl.get("delete")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderApproveAssociateModal() {
    const { approveAssociateModalOpened, selectedAssociate } = this.state;
    const associateEmail = selectedAssociate.associate.email;
    return (
      <Modal
        open={approveAssociateModalOpened}
        onClose={this.closeApproveUserModal}
      >
        <div className="modal-lg approve-associate-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2>{intl.get("approve-user")}</h2>
            </div>
            <div className="modal-body">
              <p>{intl.get("approve-user-confirmation")}</p>
              <div className="approve-associate-buttons">
                <button
                  type="button"
                  className="dast-btn dast-btn-secondary"
                  onClick={this.closeApproveUserModal}
                >
                  {intl.get("cancel")}
                </button>
                <button
                  type="button"
                  className="dast-btn dast-btn-primary"
                  onClick={e => this.handleApproveAssociate()}
                >
                  <i className="icon-user-add" />
                  {intl.get("approve")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  isEditAssociateClosed() {
    if (this._isMounted) {
      this.setState({
        isEditAssociateOpen: false,
        associateEditEmail: "",
        selector: "",
        isAddAssociateOpen: false
      });
    }
  }

  openChat = () => window.zE.activate();

  renderMobileLayout(associates, isPendingSection) {
    const {
      selectedAssociate,
      isDeletingSubuser,
      isApprovingUser
    } = this.state;
    const {
      user: { userProfile },
      account: { accountDetails }
    } = this.context;

    const { isBuyerAdmin } = accountDetails;

    return (
      <div className="associates-list-mobile">
        {associates && associates.length ? (
          associates.map((element: any) => {
            const associateEmail = element.associate.email;
            const associateName = `${element.associate.firstName} ${element.associate.lastName}`;
            return (
              <div key={associateEmail} className="associate-card">
                <div
                  className={`associate-row ${
                    isPendingSection ? "disabled" : ""
                  }`}
                >
                  <span className="associate-label">
                    {`${intl.get("name")}: `}
                  </span>
                  <span>{associateName}</span>
                </div>
                <div
                  className={`associate-row ${
                    isPendingSection ? "disabled" : ""
                  }`}
                >
                  <span className="associate-label">
                    {`${intl.get("roles")}: `}
                  </span>
                  {this.renderRoles(element)}
                </div>
                <div
                  className={`associate-row ${
                    isPendingSection ? "disabled" : ""
                  }`}
                >
                  <span className="associate-label">
                    {`${intl.get("email")}: `}
                  </span>

                  <span>{associateEmail}</span>
                </div>
                {/* eslint-disable no-nested-ternary */}
                {(isDeletingSubuser || isApprovingUser) &&
                selectedAssociate &&
                selectedAssociate.associate.email === associateEmail ? (
                  <div className="action">
                    <div className="miniLoader" />
                  </div>
                ) : userProfile.email === associateEmail ? null : (
                  <div className="action">
                    {this.renderEditButton(isPendingSection, element, true)}
                    {isBuyerAdmin && (
                      <button
                        className="dast-btn dast-btn-primary"
                        type="button"
                        onClick={e => this.openDeleteUserModal(element)}
                      >
                        <i className="icon-delete" />
                        {intl.get("delete").toUpperCase()}
                      </button>
                    )}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <p className="no-associates">{intl.get("account-no-associates")}</p>
        )}
      </div>
    );
  }

  renderRoles(element) {
    const {
      user: { userProfile },
      account: {
        accountDetails: { isBuyerAdmin, membership }
      }
    } = this.context;
    const {
      hasLeadGenRoles,
      hasLeadGenDistributorRoles,
      roles,
      leadGenDealer,
      ecommerceUser,
      ecommerceBuyer
    } = userProfile;

    const isValidDaikinManagerLeadGen = roles.includes("VIEW_ALL_REPORTS");

    const userHasLeads =
      leadGenDealer !== null &&
      leadGenDealer !== "null" &&
      leadGenDealer !== undefined;

    const daikinComfortPro = intl.get("dcp");
    const hasDcpMembership = checkMemberType(
      daikinComfortPro.toLowerCase(),
      membership
    );

    const isValidCODLeadGen =
      hasLeadGenRoles && userHasLeads && hasDcpMembership;
    const isValidINDLeadGen =
      !ecommerceUser && !ecommerceBuyer && hasLeadGenRoles && userHasLeads;
    const isValidDistributorLeadGen = hasLeadGenDistributorRoles;

    const shouldDisplayLeads =
      isValidCODLeadGen ||
      isValidINDLeadGen ||
      isValidDistributorLeadGen ||
      isValidDaikinManagerLeadGen;

    const { userEmail } = this.state;

    let filteredRoles;
    let uniqueRoles;
    const elementRoles = element.roles;
    if (elementRoles && elementRoles.length) {
      let baseRoles;
      if (shouldDisplayLeads) {
        baseRoles = elementRoles.filter(
          e =>
            e === RoleTypes.ECOMM_VIEW_CREDIT ||
            e === RoleTypes.ECOMM_VIEW_STATEMENTS ||
            e === RoleTypes.ECOMMERCE_BUYER ||
            e === RoleTypes.LEAD_DASHBOARD_ADMIN ||
            e === RoleTypes.LEADGEN_DISTRIBUTOR_ADMIN ||
            e === RoleTypes.LEAD_DASHBOARD_VIEWONLY ||
            e === RoleTypes.VIEW_DISTRIBUTOR_REPORTS ||
            e === RoleTypes.VIEW_DEALER_REPORTS
        );
      } else {
        baseRoles = elementRoles.filter(
          e =>
            e === RoleTypes.ECOMMERCE_BUYER ||
            e === RoleTypes.ECOMM_VIEW_CREDIT ||
            e === RoleTypes.ECOMM_VIEW_STATEMENTS
        );
      }
      filteredRoles = baseRoles.filter(e => {
        return intl.get(e.toLowerCase()) || null;
      });
      uniqueRoles = [...new Set(filteredRoles)];
    }
    if (uniqueRoles) {
      if (isBuyerAdmin && element.associate.email === userEmail) {
        uniqueRoles.unshift(RoleTypes.BUYER_ADMIN);
      }
      return uniqueRoles.map(r => intl.get(r.toLowerCase())).join(", ");
    }
    return null;
  }

  renderDesktopLayout(associates, isPendingSection) {
    const {
      selectedAssociate,
      isDeletingSubuser,
      isApprovingUser
    } = this.state;
    const {
      user: { userProfile },
      account: {
        accountDetails: { isBuyerAdmin }
      }
    } = this.context;

    const isLeadGenAdmin = this.checkIfLeadGenAdmin();

    return (
      <table
        className={`associates-table ${
          associates && associates.length === 0 ? "empty-table" : ""
        }`}
      >
        <thead>
          <tr>
            <th className="name-email">{intl.get("name-and-email")}</th>
            <th className="name">{`${intl.get("name").toUpperCase()}:`}</th>
            <th className="email">{`${intl.get("email").toUpperCase()}:`}</th>
            <th className="roles">{`${intl.get("roles").toUpperCase()}:`}</th>
            <th className="action">&nbsp;</th>
            <th className="action">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {associates && associates.length === 0 && (
            <tr>
              <td>{intl.get("account-no-associates")}</td>
            </tr>
          )}
          {associates &&
            associates.length &&
            associates.map((element, index) => {
              const hasDarkBackground = index % 2 > 0 ? "dark" : "";
              const associateEmail = element.associate.email;
              const associateName = `${element.associate.firstName} ${element.associate.lastName}`;

              return (
                <tr
                  key={associateEmail}
                  className={`associates-table-row-${hasDarkBackground}`}
                >
                  <td className={`name ${isPendingSection ? "disabled" : ""}`}>
                    <div className="name-part">{associateName}</div>
                  </td>
                  <td className={`email ${isPendingSection ? "disabled" : ""}`}>
                    <div className="email-part">{associateEmail}</div>
                  </td>
                  <td
                    className={`name-email ${
                      isPendingSection ? "disabled" : ""
                    }`}
                  >
                    <div className="name-part">{associateName}</div>
                    <div className="email-part">{associateEmail}</div>
                  </td>
                  <td className={`roles ${isPendingSection ? "disabled" : ""}`}>
                    {this.renderRoles(element)}
                  </td>
                  {userProfile.email === associateEmail ? (
                    <td className="action-edit" />
                  ) : (
                    <td className="action-edit">
                      {this.renderEditButton(isPendingSection, element, false)}
                    </td>
                  )}
                  {/* eslint-disable no-nested-ternary */}
                  {(isDeletingSubuser || isApprovingUser) &&
                  selectedAssociate &&
                  selectedAssociate.associate.email === associateEmail ? (
                    <td className="action">
                      <div className="miniLoader" />
                    </td>
                  ) : userProfile.email === associateEmail ||
                    (isLeadGenAdmin && !isBuyerAdmin) ? (
                    <td className="action" />
                  ) : (
                    <td className="action">
                      <button
                        className="delete-associate"
                        type="button"
                        onClick={e => this.openDeleteUserModal(element)}
                      >
                        <i className="icon-delete" />
                        {intl.get("delete").toUpperCase()}
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  }

  // Render Active users section
  renderDesktopAccountUsers() {
    const { associates } = this.state;
    return this.renderDesktopLayout(associates, false);
  }

  renderMobileAccountUsers() {
    const { associates } = this.state;
    return this.renderMobileLayout(associates, false);
  }

  // Render Pending users section
  renderDesktopPendingUsers() {
    const { pendingAssociates } = this.state;
    return this.renderDesktopLayout(pendingAssociates, true);
  }

  renderMobilePendingUsers() {
    const { pendingAssociates } = this.state;
    return this.renderMobileLayout(pendingAssociates, true);
  }

  render() {
    const {
      isLoading,
      isEditAssociateOpen,
      selector,
      associateEditEmail,
      userEmail,
      addAssociateUri,
      isAddAssociateOpen,
      error,
      pendingUserApprovalError,
      selectedAssociate,
      associate,
      pendingAssociates
    } = this.state;
    const {
      history,
      account: {
        accountDetails: { isBuyerAdmin }
      },
      user
    } = this.props;

    const {
      context: { auth }
    } = this;

    const selectedAssociateEmail =
      selectedAssociate && selectedAssociate.associate.email;
    const isLeadGenAdmin = this.checkIfLeadGenAdmin();
    const isAcceptableBuyer = isBuyerAdmin || isLeadGenAdmin;
    const { config } = getConfig();
    const { supportEmail } = config;

    return (
      <div className="content-box">
        {isLoading ? (
          <div className="loader" />
        ) : isAcceptableBuyer ? (
          <div>
            <div className="account-component">
              {pendingAssociates && pendingAssociates.length && isBuyerAdmin ? (
                <div className="associates-container">
                  <h4>{intl.get("pending-users").toUpperCase()}</h4>
                  {pendingUserApprovalError ? (
                    <div className="error-container">
                      <Messagecontainer
                        message={{
                          type: "danger-message",
                          debugMessages: pendingUserApprovalError
                        }}
                        closeContainerHandler={null}
                        stayOpen
                        button={{
                          text: intl.get("support"),
                          action: this.openChat,
                          className: "support-btn"
                        }}
                      />
                    </div>
                  ) : null}
                  {this.renderMobilePendingUsers()}
                  {this.renderDesktopPendingUsers()}
                  {selectedAssociate && this.renderDeleteAssociateModal()}
                  {selectedAssociate && this.renderApproveAssociateModal()}
                </div>
              ) : null}
              <div className="associates-container">
                <h4>{intl.get("account-users").toUpperCase()}</h4>
                {error ? (
                  <div className="error-container">
                    <Messagecontainer
                      message={{
                        type: "danger-message",
                        debugMessages: intl.get("delete-subuser-error", {
                          selectedAssociateEmail
                        })
                      }}
                      closeContainerHandler={null}
                      stayOpen
                      button={{
                        text: intl.get("support"),
                        action: this.openChat,
                        className: "support-btn"
                      }}
                    />
                  </div>
                ) : null}
                {this.renderMobileAccountUsers()}
                {this.renderDesktopAccountUsers()}
                {selectedAssociate && this.renderDeleteAssociateModal()}
                {selectedAssociate && this.renderApproveAssociateModal()}
                {isBuyerAdmin && (
                  <div className="add-associate-container">
                    <button
                      type="button"
                      aria-label={intl.get("add-user")}
                      className="dast-btn dast-btn-primary"
                      onClick={this.handleAddAssociateClicked}
                    >
                      {intl.get("add-user").toUpperCase()}
                    </button>
                  </div>
                )}
              </div>

              <B2bEditAssociate
                handleClose={this.isEditAssociateClosed}
                handleUpdate={this.handleAccountSettingsUpdate}
                user={user}
                rolesSelector={selector}
                isSelf={associateEditEmail === userEmail}
                associateEmail={associateEditEmail}
                isEditOpen={isEditAssociateOpen}
                isAddAssociateOpen={isAddAssociateOpen}
                addAssociateUri={addAssociateUri}
                history={history}
                auth={auth}
                associate={associate}
              />
            </div>
          </div>
        ) : (
          <p>
            {intl.get("forbidden-access-to-manage-users-pt1")}
            <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            {intl.get("forbidden-access-to-manage-users-pt2")}
          </p>
        )}
      </div>
    );
  }
}

export default withRouter<AccountMainProps, any>(AccountMain);
