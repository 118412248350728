import React from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { ImageSet } from "@bloomreach/spa-sdk";
import SiteLogos from "../../../SiteLogosComponent/SiteLogos";

const HeaderLogo = ({ component, page }: BrProps) => {
  if (!component || !page) {
    return null;
  }
  const { document: documentRef } = component.getModels();
  const content = documentRef && page.getContent(documentRef);
  if (!content) {
    return null;
  }
  const { image: logo1, logo2, logo3, logo4, body } = content.getData();

  const logos = [logo1, logo2, logo3, logo4]
    .filter(logo => logo)
    .map(logo => {
      const temp = logo && page.getContent<ImageSet>(logo);
      if (temp) {
        return temp.getOriginal().getUrl();
      }
      return null;
    });

  return <SiteLogos logos={logos} content={body.value} />;
};

export default HeaderLogo;
